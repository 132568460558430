import React, { useEffect, useState } from "react";
import { message } from "antd";
import "../style.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/images/cwsuite-logo.png"
import eye from "../../assets/images/eye.png"
import Loader from "./Loader"; // Assuming this is a custom component
import { getOAuthHeaders } from "../../constants/oAuthValidation";

// SignIn Component
const SignIn = () => {
    const history = useHistory();
  const Instance = process.env.REACT_APP_INSTANCE_TYPE || "Enterprise";
  const domainURL = process.env.REACT_APP_domain;

  // Query params (simulating URLSearchParams instead of Next.js router)
  const queryParams = new URLSearchParams(window.location.search);

  const username = queryParams.get("username");
  const readOnly = queryParams.get("readOnly");


  const isReadOnly = readOnly === "true";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    setLoading(true);
    check();

  }, []);

  const check = async () => {
    const authHeaders = await getOAuthHeaders();
    if (authHeaders?.token && !username) {
      setLoading(false);
      history.push("/")
    } else {
      setEmail(username)
      setLoading(false)
    }
  };

  const getDataforEnterprise = async (data) => {
    let d = new Date();
    d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days from now
    let expires = "expires=" + d.toUTCString();
    let domain = "domain=" + domainURL;
    document.cookie = "userId=" + data.userId + ";" + "expires=0" + ";path=/;secure=true;SameSite=Strict;" + domain;
    document.cookie = "Enterprise.accessToken=" + data.accessToken + ";" + "expires=0" + ";path=/;secure=true;SameSite=Strict;" + domain;
    document.cookie = "Enterprise.refreshToken=" + data.refreshToken + ";" + "expires=0" + ";path=/;secure=true;SameSite=Strict;" + domain;
    document.cookie = "Enterprise=" + true + ";" + "expires=0" + ";path=/;secure=true;SameSite=Strict;" + domain;
    document.cookie = "username=" + data.username + ";" + "expires=0" + ";path=/;secure=true;SameSite=Strict;" + domain;
    localStorage.setItem("refreshToken", data.refreshToken);

    let body2 = { token: data.accessToken };
    let body3 = { token: data.accessToken, id: null };

    try {
      var win2 = document.getElementById("myIframe2").contentWindow;
      var win3 = document.getElementById("myIframe3").contentWindow;
      const [globalParametersResponse] = await Promise.all([
        await axios({
            method: "POST",
            url:process.env.REACT_APP_genericUrl,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${body2.token}`,
            },
            data: {
              query: `query { getGlobalParameters }`,
            },
          })
      ]);

      if (globalParametersResponse.status === 200) {
        const parsedResponse  = JSON.parse(globalParametersResponse.data.data.getGlobalParameters)
        localStorage.setItem("userData", JSON.stringify(parsedResponse.User));
        history.push("/")
  
      } else {
        setLoading(false);
        displayErrorMessage();
      }

     
    } catch (error) {
      if (error.message === "Request failed with status code 500") {
        setLoading(false);
        displayErrorMessage();
      }
    }
  };

  const displayErrorMessage = () => {
    message.error({
      content: (
        <div>
          <p>Unable to load necessary data for your session. Please try logging in again to refresh the system.</p>
          <p>If the issue persists, contact your system administrator.</p>
        </div>
      ),
      duration: 10,
      style: { whiteSpace: "pre-wrap" },
    });
  };

  const handleLoginForEnterprise = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      message.error("Email and password are required.");
      return;
    }
    setLoading(true);
    const payload = {
      username: email,
      password: password,
    };
    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_signInUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload
      });
      console.log(res)
      if (res.status === 200) {
        getDataforEnterprise(res.data);
      } else {
        setLoading(false);
        message.error("Something is wrong in upsertUserSession");
      }
    } catch (error) {
      setLoading(false);
      message.error("Bad Credentials");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = () => {
    setLoading(true);
    window.location.href = "/sign-up"; // Replace Router.push with window.location.href
  };

  const handleForgotPassword = () => {
    setLoading(true);
    window.location.href = "/forgot-password"; // Replace Router.push with window.location.href
  };

  return (
    <>
      <section style={{ height: "100vh", width: "100%", backgroundColor: "#F3F4F9", padding: "2.5rem 0" }}>
        <div style={{ marginTop: "3rem", position: "relative",display:"grid" }}>
          <div
            className="loginDiv"
            style={{
              maxWidth: "27rem",
              margin: "0 auto",
              backgroundColor: "#F3F4F9",
            //   border: "1px solid #e2e2e2",
            //   padding: "20px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2.5rem" }}>
              {/* Replace next/image with standard img tag */}
              <img src={logo} alt="LOGO" style={{  height: "28px" }} />
            </div>
            <div style={{ maxWidth: "28rem", margin: "0 auto" }}>
              <form style={{ margin: "1rem 2.5rem 0" }} onSubmit={handleLoginForEnterprise}>
                <label style={{ fontFamily: "Inter", fontSize: "14px", opacity: 0.8, color: "#101828" }}>Username</label>
                <input
                  readOnly={isReadOnly}
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "0.25rem",
                    padding: "8px 12px",
                    color: "#101828",
                    border: "0.5px solid #DaDaDa",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    outline: "none",
                    marginBottom: "1rem",
                  }}
                  required
                  type="text"
                  placeholder="Enter Email"
                  value={email}
                  id="username"
                  name="username"
                  onChange={(ev) => setEmail(ev.target.value)}
                />
                <label style={{ fontFamily: "Inter", fontSize: "14px", opacity: 0.8, color: "#101828" }}>Password</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    style={{
                      width: "100%",
                      height: "40px",
                      padding: "8px 12px",
                      border: "1px solid #dadada",
                      color: "#101828",
                      borderRadius: "4px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      outline: "none",
                    }}
                    required
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                  />
                  <img
                    onClick={togglePasswordVisibility}
                    style={{ marginLeft: "-36px", cursor: "pointer" }}
                    src={eye}
                    alt="Toggle password visibility"
                    width={15}
                  />
                </div>
                <button
                  id="login"
                  name="login"
                  onClick={handleLoginForEnterprise}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "50px",
                    margin: "20px 0 8px",
                    backgroundColor: "#91C507",
                    color: "#fff",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  Login
                </button>
              </form>
              <div
                style={{
                  margin: "8px 40px 28px",
                  display: Instance === "Enterprise" ? "none" : "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <a
                  onClick={handleSignUp}
                  style={{ color: "#0500ED", fontFamily: "Inter", fontSize: "0.8rem", cursor: "pointer" }}
                >
                  Sign Up
                </a>
                <p
                  onClick={handleForgotPassword}
                  style={{
                    color: "#0500ED",
                    fontFamily: "Inter",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  Forgot Password?
                </p>
              </div>
            </div>
          </div>
          {loading && (
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                // backgroundColor: "rgba(255, 255, 255, 0.87)",
                borderRadius: "12px",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </section>

      {/* Hidden iframes for postMessage (if needed) */}
      <iframe id="myIframe2" style={{ display: "none" }} />
      <iframe id="myIframe3" style={{ display: "none" }} />
    </>
  );
};



// Example usage in a React app
const App = () => {

//   useEffect(() => {
//     // Fetch geolocation client-side
//     axios
//       .get("http://ip-api.com/json/")
//       .then((res) => {
//         setGeoLocation(`${res.data.city}, ${res.data.country}`);
//       })
//       .catch((err) => {
//         console.error("Error fetching geolocation:", err);
//       });
//   }, []);

  return <SignIn  />;
};

export default SignIn;