// src/lib/hooks/QRCodeModal.js
import React, { useEffect, useState } from "react"; // Added useState
import { Modal, message } from "antd";
import axios from "axios";

const QRCodeModal = ({ qrCodeData, paymentMethod, visible, onClose, qrPaymentCallback, setQRCodeModalVisible }) => {
  const API_URL = `${process.env.REACT_APP_API_URL}api/paymentGateway/razorPay`;
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (!visible || !qrCodeData) return;

    let poll; // Declare poll variable outside to access it in cleanup
    let timer; // Declare timer variable for countdown

    const payloadData = paymentMethod?.paymentProviderDetails?.providerConfigurations;
    const stringifiedData = payloadData ? JSON.stringify(payloadData) : null;

    const pollPaymentStatus = async () => {
      let attempts = 0;
      const maxAttempts = 12; // Poll for ~1 minute (12 * 5s)
      const interval = 5000;
      const lastSeconds = 20; // Last 20 seconds threshold

      poll = setInterval(async () => {
        attempts += 1;
        const secondsElapsed = attempts * (interval / 1000); // Convert to seconds
        const totalDuration = maxAttempts * (interval / 1000); // Total duration in seconds
        const remainingSeconds = totalDuration - secondsElapsed;

        // Start timer when remaining time is 20 seconds or less
        if (remainingSeconds <= lastSeconds && !timer) {
          setTimeLeft(Math.ceil(remainingSeconds)); // Initialize timer
          timer = setInterval(() => {
            setTimeLeft((prev) => {
              if (prev <= 1) {
                clearInterval(timer); // Stop timer when it reaches 0
                return 0;
              }
              return prev - 1;
            });
          }, 1000); // Update every second
        }

        try {
          const statusResponse = await axios.get(`${API_URL}/poll-qr?qrId=${qrCodeData.id}&payloadData=${stringifiedData}`);
          console.log("polling response=========", statusResponse.data);
          const { payments_count_received, status } = statusResponse.data;

          if (status === "closed") {
            clearInterval(poll); // Stop polling when status is "closed"
            if (timer) clearInterval(timer); // Stop timer if running
            qrPaymentCallback(statusResponse.data);
            setQRCodeModalVisible(false);
            // message.success("Payment Success");
            onClose();
          } else if (attempts >= maxAttempts) {
            onClose();
            clearInterval(poll); // Stop polling after max attempts
            if (timer) clearInterval(timer); // Stop timer if running
            message.info("Payment timed out");
          }
        } catch (error) {
          clearInterval(poll); // Stop polling on error
          if (timer) clearInterval(timer); // Stop timer if running
          message.error("Payment Failed: Error checking status");
          console.error("Error polling Razorpay QR status:", error);
        }
      }, interval);
    };

    pollPaymentStatus();

    // Cleanup when visible changes to false or component unmounts
    return () => {
      if (poll) clearInterval(poll); // Stop polling
      if (timer) clearInterval(timer); // Stop timer
      setTimeLeft(null); // Reset timer state
    };
  }, [visible, qrCodeData, qrPaymentCallback, onClose, setQRCodeModalVisible]);

  return (
    <Modal
      title="Scan to Pay"
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "400px",
      }}
    >
      {qrCodeData && (
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <img
            src={qrCodeData.image_url}
            alt="Razorpay QR Code"
            style={{
              width: "75%",
              maxWidth: "450px",
              height: "73vh",
              margin: "0 auto",
              display: "block",
            }}
          />
          <p
            style={{
              marginTop: "20px",
              fontSize: "16px",
            }}
          >
            Scan the QR code to complete the payment
          </p>
          {timeLeft !== null && (
            <p
              style={{
                marginTop: "10px",
                fontSize: "16px",
                color: "red",
              }}
            >
              Time remaining: {timeLeft} seconds
            </p>
          )}
        </div>
      )}
    </Modal>
  );
};

export default QRCodeModal;
