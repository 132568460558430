export const paymentProcess = async (type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb) => {
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies[0]?.denominations?.sort((a, b) => a.value - b.value) || [];
  const precision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  let denominationValue = currenciesList.length > 0 ? currenciesList[0]?.value : 1;
  const calculateRoundingDifference = (value) => {
    const overpayment = value - cart.paid;

    const change = Math.floor(overpayment / denominationValue) * denominationValue;
    // Remaining amount is treated as overpayment
    const remainingOverpayment = overpayment - change;
    const changeAmount =
      cart.paid + parseFloat(value) - Math.abs(cart.total) > 0
        ? (cart.paid + parseFloat(value) - Math.abs(Math.abs(cart.total) + Math.abs(remainingOverpayment))).toFixed(2)
        : cart?.change <= 0
        ? "0.00"
        : cart?.change;
    const floatAmount = parseFloat(Math.floor(changeAmount / 1).toFixed(precision));

    setOverPayedAmount(changeAmount - floatAmount > 0 ? (changeAmount - floatAmount).toFixed(precision) : 0);
    // setOverPayedAmount(remainingOverpayment > 0 ? remainingOverpayment.toFixed(precision) : 0);
  };

  if (type === "1") {
    if (tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N") {
      return null;
    }

    if (amount === "" && value === "x") {
      setAmount("");
    } else if (value === "x") {
      setAmount(`${amount.toString().substring(0, amount.toString().length - 1)}`);
      if (cart.total <= parseFloat(`${amount.toString().substring(0, amount.toString().length - 1)}`) && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(`${amount.toString().substring(0, amount.toString().length - 1)}`));
      } else {
        setOverPayedAmount(0);
      }
    } else if (Object.keys(selectedPaymentMethod).length > 0) {
      if (numb === 0) {
        setNumb(numb + 1);
        setAmount(`${value}`);
        if (cart.total <= parseFloat(value) && selectedPaymentMethod.name.toLowerCase() === "cash") {
          calculateRoundingDifference(parseFloat(`${parseFloat(value)}`));
        } else {
          setOverPayedAmount(0);
        }
      } else {
        setAmount(`${amount}${value}`);
        let cashAmount = parseFloat(`${amount}${value}`);
        if (cart.total <= cashAmount && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
          calculateRoundingDifference(cashAmount);
        } else {
          setOverPayedAmount(0);
        }
      }
    }
  } else if (type === "2") {
    if (numb === 0) {
      setNumb(numb + 1);
      setAmount(parseFloat(0) + parseFloat(value));
      if (cart.total - cart.paid <= value && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(0) + parseFloat(value));
      } else {
        setOverPayedAmount(0);
      }
    } else {
      setAmount(parseFloat(amount !== "" ? amount : 0) + parseFloat(value));
      if (cart.total - cart.paid <= parseFloat(value) + parseFloat(amount !== "" ? amount : 0) && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(amount !== "" ? amount : 0) + parseFloat(value));
      } else {
        setOverPayedAmount(0);
      }
    }
  } else if (type === "3") {
    let cashTotal = cart.payments.reduce((total, item) => parseFloat(total) + parseFloat(item.amount), 0);
    if (Object.keys(selectedPaymentMethod).length > 0) {
      if (cart.total <= parseFloat(value) + parseFloat(cashTotal) && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(value) + parseFloat(cashTotal));
      } else {
        setOverPayedAmount(0);
      }
      setAmount(value);
    }
  }
};
