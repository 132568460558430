import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Typography, Modal, Select } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchOutlined, CloseSquareFilled } from "@ant-design/icons";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import Calender from "../../../../assets/images/calender.svg";
import "../restaurantStyle.css";
import moment from "moment";
import db from "../../../../database";
import { SyncData } from "./SyncData";
import MenuLayout from "../MenuLayout";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { getMultilingualText } from "../../../../utility/getMultilingualText ";
import OrderListComponent from "../OrderListComponent";
const TableLayout = (props) => {
  const {
    handleAmount,
    handleCashPayment,
    manualDiscountRestaurant,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    restaurantSections,
    mainTableData,
    handleTable,
    handleReserveTable,
    occupiedDataTablesData,
    reservedTablesData,
    userPreferences,
    setOcupiedTablesData,
    setReservedTablesData,
    showProducts,
    setShowProducts,
    categorieFlag,
    setCategorieFlag,
    addDefinedProductWithAddons,
    restaurantProductCategory,
    productsData,
    pickProduct,
    increaseProductQty,
    decreaseProductQty,
    deleteProduct,
    setDisplayCustomerSearch,
    handleVoid,
    setPaymentVisible,
    paymentVisible,
    cart,
    setCart,
    deleteCart,
    setKeyValue,
    handleOrder,
    tableCards,
    setTableCards,
    setProductsData,
    saleTypeProducts,
    setSaleTypeProducts,
    filteredPaymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    requestPayment,
    setNumb,
    amount,
    setAmount,
    showOrderCompleteFlag,
    setShowOrderCompleteFlag,
    processOrder,
    tableList,
    setTableList,
  } = props;
  const [selectedFloor, setSelectedFloor] = useState({ value: "all", name: "All" });
  const [reservedButtonFlag, setReservedButtonFlag] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [currentTable, setCurrentTable] = useState({});
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const isMultilingual = process.env.REACT_APP_MULTILINGUAL === "true";
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  // const currentDateTime = useState(moment(new Date()).format(userPreferences.dateFormat));
  const tableData = tableCards;
  const { Text } = Typography;
  const { t } = useTranslation();
  const multilingual = process.env.REACT_APP_MULTILINGUAL === "true" && localStorage.getItem("selectedLanguage") !== "English";
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage") || process.env.REACT_APP_LANGUAGE || "English";
    i18n.changeLanguage(savedLanguage);
  }, []);

  useEffect(() => {
    // Update the current date and time every second
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;

  const handleButtonClick = (floor) => {
    let tempData = [];
    if (floor.name === "All") {
      tempData.push(...mainTableData);
      setSelectedFloor(floor);
    } else {
      mainTableData.map((e) => {
        if (e.cwrFbsectionId === floor.value) {
          tempData.push(e);
          setSelectedFloor(floor);
        }
      });
    }
    setTableCards(tempData);
  };

  const handleCancel = async (values) => {
    Modal.confirm({
      title: "This action will cancel the table.Do you wish to proceed?",
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {
        const table = await db.tableData.where("cwrFbTableId").equals(values.cwrFbTableId).first();
        if (table) {
          table.statusType = "OPN";
          table.guestName = "";
          table.color = "rgba(146, 144, 152, 0.5)";
          table.noOfPersons = "";
          table.guestType = "";
          table.referredBy = "";
          table.tableSync = "N";
          table.salesRepId = table.waiter;
          table.fbOrderSync = "N";
          table.lines = [];
          table.items = [];
          await db.tableData.put(table);
          SyncData(table, "upsertTableStatus");
          setCurrentTable(table);
          const updatedTableData = await db.tableData.toArray();
          setTableCards(updatedTableData);

          const updatedOccupiedData = updatedTableData.filter((table) => table?.statusType === "OCU");
          setOcupiedTablesData(updatedOccupiedData);
          const updatedReservedData = updatedTableData.filter((table) => table.statusType === "RES");
          setReservedTablesData(updatedReservedData);

          let tempData = [];
          await db.tableData.toArray().then((fetched) => {
            tempData.push(...fetched);
          });
          setTableList(tempData);
        }
      },
    });
  };
  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    // document.body.dir = value === "Arabic" ? "rtl" : "ltr";
    localStorage.setItem("selectedLanguage", value);
  };
  return (
    <>
      {showProducts ? (
        <MenuLayout
          {...props}
          {...{ setKeyValue, deleteCart, cart, userPreferences, handleCashPayment }}
          setProductsData={setProductsData}
          categorieFlag={categorieFlag}
          setCategorieFlag={setCategorieFlag}
          addDefinedProductWithAddons={addDefinedProductWithAddons}
          restaurantProductCategory={restaurantProductCategory}
          productsData={productsData}
          pickProduct={pickProduct}
          increaseProductQty={increaseProductQty}
          decreaseProductQty={decreaseProductQty}
          deleteProduct={deleteProduct}
          setDisplayCustomerSearch={setDisplayCustomerSearch}
          handleVoid={handleVoid}
          paymentVisible={paymentVisible}
          setPaymentVisible={setPaymentVisible}
          handleOrder={handleOrder}
          showProducts={showProducts}
          setShowProducts={setShowProducts}
          saleTypeProducts={saleTypeProducts}
          setSaleTypeProducts={setSaleTypeProducts}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setNumb={setNumb}
          requestPayment={requestPayment}
          amount={amount}
          setAmount={setAmount}
          showOrderCompleteFlag={showOrderCompleteFlag}
          setShowOrderCompleteFlag={setShowOrderCompleteFlag}
          processOrder={processOrder}
          manualDiscountRestaurant={manualDiscountRestaurant}
          handleAmount={handleAmount}
        />
      ) : paymentVisible ? (
        <OrderListComponent {...props} requestPayment={requestPayment} />
      ) : (
        <div style={{ display: "flex" }}>
          <div style={{ width: "73%", height: "100vh" }}>
            <Row style={{ fontSize: "1vw", paddingRight: 0 }}>
              <Col span={isMultilingual ? 15 : 18} className="floor-buttons">
                {restaurantSections?.map((section) => (
                  <Button className={`floor-button ${selectedFloor.name === section.name ? "selected" : ""}`} onClick={() => handleButtonClick(section)}>
                    {section.name}
                  </Button>
                ))}
              </Col>
              <Col span={6}>
                {/* <img src={Calender} style={{height:"3vh",}}/> */}
                <div style={{ fontWeight: 500, fontFamily: "Inter", fontSize: "1.1em", color: "#929098", paddingTop: "3.4vh", paddingLeft: "1vw" }}>
                  <img src={Calender} style={{ height: "3vh", paddingRight: "0.3em", paddingBottom: "0.1rem" }} />
                  {/* {currentDateTime} */}
                  {formattedDateTime}
                </div>
              </Col>
              {isMultilingual && (
                <Col span={3} className="floor-buttons" style={{ display: "block" }}>
                  <Select
                    value={i18n.language}
                    onChange={handleLanguageChange}
                    options={[
                      { label: "English", value: "English" },
                      { label: "Arabic", value: "Arabic" },
                      { label: "French", value: "French" },
                    ]}
                  />
                </Col>
              )}
            </Row>
            <div className="tables-container">
              <Scrollbars autoHide style={{ height: "77vh" }}>
                <InfiniteScroll
                  dataLength={tableCards.length}
                  hasMore={true}
                  endMessage={
                    <p className="center">
                      <b>Yay! you have seen it all</b>
                    </p>
                  }
                  style={{ overflowX: "hidden" }}
                >
                  <Row gutter={[16, 16]} style={{ rowGap: "11px" }}>
                    {tableData.map((table) => (
                      <Col key={table.id} xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card
                          className={`table-card ${table.statusType}`}
                          onClick={() => {
                            handleTable(table);
                          }}
                        >
                          <div className="table-title">{table.name}</div>
                          <div className="table-status">
                            {table.statusType === "OCU"
                              ? getMultilingualText("Checked-in")
                              : table.statusType === "OPN"
                              ? getMultilingualText("Available")
                              : getMultilingualText("Reserved")}
                            {/* {table.statusType !== "OPN" ? table?.time && `| ${table?.time}` : null} */}
                            <div className="table-status">
                              {table.statusType === "RES" ? `${table?.reservedTime || ""}` : table.statusType === "OCU" && table?.time ? `${table.time}` : null}
                            </div>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              </Scrollbars>
              <div>
                <Row
                  style={{
                    backgroundColor: "#fff",
                    height: "8vh",
                    borderRadius: "30px",
                    padding: "0 1vw",
                    position: "absolute",
                    width: "95.5%",
                    bottom: "-6vh",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Col span={14}>
                    <div
                      class="status-indicator"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "8vh",
                        fontSize: "1vw",
                      }}
                    >
                      <span className="available">{getMultilingualText("Available")}</span>
                      <span className="reserved">{getMultilingualText("Reserved")}</span>
                      <span className="checked-in">{getMultilingualText("Checked-in")}</span>
                    </div>
                  </Col>
                  <Col
                    span={10}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "100%",
                      height: "8vh",
                      fontSize: "1vw",
                      // display: posConfig.enableTableReservation === "Y" ? "block" : "none",
                    }}
                  >
                    {posConfig.enableTableReservation === "Y" && (
                      <Button
                        style={{ backgroundColor: "#2F3856", color: "#fff", borderRadius: "10px", height: "6vh", fontSize: "1em", fontWeight: "500", fontFamily: "Inter" }}
                        onClick={handleReserveTable}
                      >
                        {getMultilingualText("Reserve Table")}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div style={{ background: "#fff", height: "100vh", width: "27%", fontSize: "1vw", overflow: "hidden" }}>
            <div style={{ padding: "0 1vw" }}>
              {/* <Input
            placeholder="Search here"
            // prefix={<SearchOutlined />}
            prefix={<img src={SearchIcon} alt="" style={{ paddingLeft: "5px", paddingRight: 0, height: "2.4vh" }} />}
            className="search-input"
          /> */}

              <Input
                placeholder={getMultilingualText("Search here")}
                // prefix={<SearchOutlined />}
                prefix={<img src={SearchIcon} alt="" style={{ paddingLeft: "5px", paddingRight: 0, height: "2.4vh" }} />}
                className="search-input"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                suffix={
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {searchInput.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856", cursor: "pointer" }} onClick={() => setSearchInput("")} /> : null}
                  </div>
                }
              />
            </div>

            <Row>
              <Col span={12} style={{ padding: "2vh 0.5vw 1vh 1vw" }}>
                <Button
                  style={{
                    backgroundColor: reservedButtonFlag ? "#fff" : "#2F3856",
                    color: reservedButtonFlag ? "#929098" : "#fff",
                    borderRadius: "35px",
                    width: "100%",
                    fontSize: "1em",
                    fontWeight: "500",
                    padding: "1vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    height: "auto",
                    lineHeight: "1.5",
                  }}
                  onClick={() => {
                    setReservedButtonFlag(false);
                    setCurrentTable({});
                  }}
                >
                  Reserved {reservedTablesData.length}
                  {multilingual && (
                    <span style={{ display: "block", marginTop: "0.5vh" }}>
                      {t("Reserved")} {reservedTablesData.length}
                    </span>
                  )}
                </Button>
              </Col>

              <Col span={12} style={{ padding: "2vh 1vw 1vh 0.5vw" }}>
                <Button
                  style={{
                    backgroundColor: !reservedButtonFlag ? "#fff" : "#2F3856",
                    color: !reservedButtonFlag ? "#929098" : "#fff",
                    borderRadius: "35px",
                    width: "100%",
                    fontSize: "1em",
                    fontWeight: "500",
                    padding: "1vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    height: "auto",
                    lineHeight: "1.5",
                  }}
                  onClick={() => {
                    setReservedButtonFlag(true);
                    setCurrentTable({});
                  }}
                >
                  Checked-in {occupiedDataTablesData.length}
                  {multilingual && (
                    <span style={{ display: "block", marginTop: "0.5vh" }}>
                      {t("Checked-in")} {occupiedDataTablesData.length}
                    </span>
                  )}
                </Button>
              </Col>
            </Row>

            <Scrollbars autoHide style={{ height: "66vh" }}>
              <div>
                {reservedButtonFlag
                  ? occupiedDataTablesData
                      ?.filter(
                        (occupiedData) =>
                          occupiedData.name?.toLowerCase().includes(searchInput.toLowerCase()) || occupiedData.guestName?.toLowerCase().includes(searchInput.toLowerCase())
                      )
                      ?.map((occupiedData) => {
                        const isSelected = occupiedData.name === currentTable?.name;
                        return (
                          <Card
                            style={{
                              width: "100%",
                              border: "none",
                            }}
                            bodyStyle={{
                              padding: "1vh",
                            }}
                            onClick={() => setCurrentTable(occupiedData)}
                          >
                            <div
                              style={{
                                backgroundColor: isSelected ? "#9290984D" : "#fff",
                                borderRadius: 25,
                                display: "flex",
                                alignItems: "center",
                                height: "7vh",
                                fontSize: "1vw",
                                cursor: "pointer",
                              }}
                            >
                              <Text strong style={{ textAlign: "left", width: "18vw" }}>
                                <span
                                  style={{
                                    paddingRight: "2vw",
                                    paddingLeft: "1vw",
                                    fontSize: "1em",
                                    color: "#0F0718",
                                    fontWeight: 500,
                                  }}
                                >
                                  {occupiedData?.name}
                                </span>{" "}
                                <span>{occupiedData?.guestName}</span>
                              </Text>
                              <Text
                                style={{
                                  textAlign: "right",
                                  fontSize: "1em",
                                  color: "#929098",
                                  fontWeight: 500,
                                }}
                              >
                                {occupiedData.noOfPersons} Persons
                              </Text>
                            </div>
                          </Card>
                        );
                      })
                  : reservedTablesData
                      ?.filter(
                        (reservedData) =>
                          reservedData.name?.toLowerCase().includes(searchInput.toLowerCase()) || reservedData.guestName?.toLowerCase().includes(searchInput.toLowerCase())
                      )
                      ?.map((reservedData) => {
                        const isSelected = reservedData.name === currentTable?.name;
                        return (
                          <Card
                            style={{
                              width: "100%",
                              border: "none",
                              cursor: "pointer",
                            }}
                            bodyStyle={{
                              padding: "1vh",
                            }}
                            onClick={() => setCurrentTable(reservedData)}
                          >
                            <div
                              style={{
                                backgroundColor: isSelected ? "#9290984D" : "#fff",
                                borderRadius: 25,
                                display: "flex",
                                alignItems: "center",
                                height: "7vh",
                              }}
                            >
                              <Text strong style={{ textAlign: "left", width: "18vw" }}>
                                <span
                                  style={{
                                    paddingRight: "2vw",
                                    paddingLeft: "1vw",
                                    fontSize: "1em",
                                    color: "#0F0718",
                                    fontWeight: 500,
                                  }}
                                >
                                  {reservedData?.name}
                                </span>{" "}
                                <span>{reservedData?.guestName}</span>
                              </Text>
                              <Text
                                style={{
                                  textAlign: "right",
                                  fontSize: "1em",
                                  color: "#929098",
                                  fontWeight: 500,
                                }}
                              >
                                {reservedData.noOfPersons} Persons
                              </Text>
                            </div>
                          </Card>
                        );
                      })}
              </div>
            </Scrollbars>

            {(reservedButtonFlag || reservedTablesData.length > 0) && currentTable?.name && (
              <Row
                style={{
                  paddingTop: "9vh",
                  display: "flex",
                  justifyContent: "space-between",
                  display: currentTable.name ? "flex" : "none",
                }}
              >
                <Col
                  span={12}
                  style={{
                    padding: "0 0.5vw 0 1vw",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    style={{
                      background: "#fff",
                      color: "#2F3856",
                      borderRadius: "10px",
                      width: "100%",
                      fontSize: "1em",
                      fontWeight: "500",
                      height: "6vh",
                    }}
                    onClick={() => handleCancel(currentTable)}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: "0 1vw 0 0.5vw",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => handleTable(currentTable)}
                    style={{
                      background: "#2F3856",
                      color: "#fff",
                      borderRadius: "10px",
                      width: "100%",
                      fontSize: "1em",
                      fontWeight: "500",
                      height: "6vh",
                    }}
                  >
                    Place order
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TableLayout;
