import Axios from "axios";
import { message } from "antd";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import { verifyStock } from "../../../services";

const authHeaders = getOAuthHeaders();
const serverUrl = process.env.REACT_APP_serverUrl;

export const stock = async (props) => {
  const { cart, setCart, tillData, setProductStock, isFinalValidation, setTempCart } = props;
  const consolidateCart = (cart) => {
    const itemsMap = new Map();

    cart.items.forEach((item) => {
      const uniqueKey = `${item.value}_${item.mBatchId}`;
      if (itemsMap.has(uniqueKey)) {
        // Add weight to the existing entry
        itemsMap.get(uniqueKey).weight += item.weight;
      } else {
        // Add a new entry
        itemsMap.set(uniqueKey, { ...item });
      }
    });

    // Convert the Map back to an array
    const consolidatedItems = Array.from(itemsMap.values());

    return { ...cart, items: consolidatedItems };
  };

  const cleanedCart = consolidateCart(cart);
  console.log(cleanedCart);

  const buildCartProducts = cleanedCart.items
    .map(({ value, weight, mBatchId }) => `{ productId: "${value}",,mBatchId: ${mBatchId ? `"${mBatchId}"` : null}, orderQty: ${weight} }`)
    .join(", ");

  const data = {
    query: `
      query { 
        verifyStock(order: {
          orderNo: "${cart.documentno}"
          tillId: "${tillData.tillAccess.cwrTill.searchKey}"
          cartItems: [${buildCartProducts}]
        }) {
          orderNo
          tillId
          cartItems {
            productId
            orderQty
            mBatchId
            availabilityStatus
            availableQty
          }
        }
      }
    `,
  };

  try {
    const response = await verifyStock(data);

    if (response.status === 200) {
      const { cartItems } = response.data.data.verifyStock;
      let totalFlag = true;

      const updateCartItems = (item) => {
        const resItem = cartItems.find((resItem) => resItem.productId === item.value && (item.mBatchId ? resItem.mBatchId === item.mBatchId : true));

        if (!resItem) return item;

        if (resItem.availabilityStatus !== "Yes" && resItem.availableQty < item.weight) {
          totalFlag = false;
        } else if (resItem.availabilityStatus !== "Yes") {
          resItem.availableQty -= item.weight;
        }

        return {
          ...item,
          availabilityStatus: resItem.availabilityStatus,
          availableQty: resItem.availableQty,
        };
      };

      if (isFinalValidation) {
        const updatedCartData = cart.items.map(updateCartItems);
        const updatedCart = { ...cart, items: updatedCartData };
        setCart(updatedCart);
        localStorage.setItem("cartObj", JSON.stringify(updatedCart));
        setTempCart(JSON.parse(JSON.stringify(updatedCart)));

        return totalFlag;
      } else {
        const hasInsufficientStock = cart.items.some((item) => {
          const resItem = cartItems.find(
            (resItem) => resItem?.productId === item?.value && item?.type === "I" && item?.layAway !== "Y" && (item.mBatchId ? resItem.mBatchId === item.mBatchId : true)
          );

          if (resItem && resItem?.availabilityStatus !== "Yes") {
            if (resItem.availableQty < item.weight) return true;
            resItem.availableQty -= item.weight;
          }

          return false;
        });

        if (hasInsufficientStock && tillData.tillAccess.csBunit.allowNegativeStock === "N") {
          setProductStock(true);
          return false;
        }
      }

      return true;
    }
  } catch (error) {
    console.error("Error checking stock:", error);
    return false;
  }
};
