import { useEffect } from "react";

let dataString = "";
let timeoutId;

const BarCodeComponent = (props) => {
  const { onScan, setProductSearchInput, productSearchInput, barcodeProps } = props;
  const tillData = JSON.parse(localStorage.getItem("tillData"));

  const handleKeyPress = (e) => {
    const { charCode, key } = e;

    // Reset timeout if it exists
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    // Set a timeout to clear dataString after inactivity
    timeoutId = setTimeout(() => {
      dataString = "";
      timeoutId = undefined;
    }, 200); // Reduced to 100ms for faster reset between scans

    // Build the barcode string
    if (
      (charCode >= 48 && charCode <= 57) || // 0-9
      (charCode >= 65 && charCode <= 90) || // A-Z
      (charCode >= 97 && charCode <= 122) || // a-z
      charCode === 45 || // -
      charCode === 32 // space
    ) {
      dataString += key;
    }

    // Process barcode when Enter (charCode 13) is pressed
    const productName = localStorage.getItem("productName");
    const productSegment = localStorage.getItem("productSegment");
    if (charCode === 13 && dataString !== "" && productName === null && !productSegment) {
      setProductSearchInput(""); // Clear search input
      let products = [];
      const posConfig = tillData?.posScanConfigs?.filter((item) => item.formula !== null && item.scanTrxType === "PR");
      const posConfigItem = posConfig?.filter((item) => dataString.startsWith(item.startsWith));

      if (posConfigItem?.length > 0) {
        const config = posConfigItem[0];
        if (config.delimiter) {
          products = dataString.split(config.delimiter).filter(Boolean); // Split and remove empty entries
        }
      }
      console.log("products", dataString);

      // Set a delay before calling onScan
      const delay = 200; // Delay in milliseconds (adjust as needed)
      clearTimeout(timeoutId); // Clear previous timeout if exists

      timeoutId = setTimeout(() => {
        // console.log("Scan dataString", dataString);
        // onScan(dataString.trim(), false);
        const cleanedData = dataString.replace(/\s+/g, ""); // Remove all spaces & newlines

        // Ensure that each product is exactly 13 characters long
        products = cleanedData.match(/.{1,13}/g) || [];
        processProducts(products.length > 0 ? products : [dataString]);

        // Reset state
        dataString = "";
        timeoutId = undefined;
      }, delay);
    } else if (productName !== null && charCode === 13 && !productSegment) {
      let prodCode = localStorage.getItem("productName");
      setProductSearchInput("");
      dataString = "";
      localStorage.removeItem("productName", "");
      onScan(prodCode, false);
    }
  };

  // Sequential processing of products
  const processProducts = async (products) => {
    try {
      for (const product of products) {
        await onScan(product, false); // Wait for each onScan to complete
        await new Promise((resolve) => setTimeout(resolve, 300)); // Small delay to prevent overload
      }
      console.log("All products processed successfully.");
    } catch (error) {
      console.error("Error processing products:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      dataString = ""; // Reset on unmount
      clearTimeout(timeoutId); // Clear any pending timeout
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []); // Empty dependency array is fine here since tillData is static

  return null;
};

export default BarCodeComponent;
