import axios from "axios";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";

export const processRazorpayQRPayment = async (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  setLoading,
  dispatchQRCodeEvent,
  closeQRCodeModal,
  setLayoutType,
  setKioskUI
) => {
  const trxId = uuidv4().replace(/-/g, "").toUpperCase();
  const API_URL = `${process.env.REACT_APP_API_URL}api/paymentGateway/razorPay`;

  setLoader(true);
  setPaymentModal(false);

  const payloadData = paymentMethod?.paymentProviderDetails?.providerConfigurations;
  // console.log("payloadData====================", payloadData);

  try {
    const qrResponse = await axios.post(`${API_URL}/create-qr`, {
      payment_amount: amountAdded * 100,
      description: `Payment for Order ${cart.documentno || trxId}`,
      close_by: Math.floor(Date.now() / 1000) + 45 * 24 * 60 * 60,
      notes: { purpose: "POS Razorpay QR Payment" },
      payloadData,
    });

    const { id: qrId, image_url } = qrResponse.data;

    // Log QR code details (for debugging; replace with UI display in frontend)
    console.log("Razorpay QR Generated:", { qrId, image_url });
    const paymentResponse = await new Promise((resolve) => {
      dispatchQRCodeEvent(qrResponse.data, paymentMethod, (eventData) => {
        console.log("Razorpay QR Event Data:", eventData);
        resolve({ ...eventData });
      });
    });
    console.log("Razorpay QR Event Data:", paymentResponse);
    if (paymentResponse.status === "closed") {
      completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0, setLayoutType, setKioskUI);
    } else {
      setSelectedPaymentMethod({});
      setKioskUI(5);

      const customEvent = new CustomEvent("customStorageChange", {
        detail: { key: "kioskUI", newValue: 5 },
      });
      window.dispatchEvent(customEvent);
      localStorage.setItem("kioskUI", 5);
      setLayoutType(0);
      localStorage.setItem("layoutType", 0);

      // Dispatch a custom event to notify other parts of the UI
      const layoutEvent = new CustomEvent("customStorageChange", {
        detail: { key: "layoutType", newValue: 0 },
      });
      window.dispatchEvent(layoutEvent);
    }
  } catch (error) {
    setLoader(false);
    message.error("Payment Failed: Unable to generate QR code");
    console.error("Error generating Razorpay QR:", error);
  }
};
