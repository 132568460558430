import axios from "axios";
import moment from "moment";
import db from "../database";
import { v4 as uuidv4 } from "uuid";
import { getOAuthHeaders } from "../constants/oAuthValidation";

let authHeaders = {}; // Define it first to avoid errors

setTimeout(() => {
  authHeaders = getOAuthHeaders() || {}; 
  const { access_token } = authHeaders;

  if (access_token) {
    console.log("Token exists:", access_token);
  } else if (window.location.pathname !== "/sign-in") {
    window.location.assign("/sign-in");
  }
}, 0);


// if (access_token) {
//   console.log("Token exists:", access_token);
// } else {
//   // Prevent infinite redirect loop
//   if (window.location.pathname !== "/sign-in") {
//     window.location.assign("/sign-in"); // No #
//   }
// }

const retailURL = process.env.REACT_APP_serverUrl;
const domainURL = process.env.REACT_APP_domain;
const refreshTokenURL = process.env.REACT_APP_REFRESHTOKEN_URL;
// let tillId;
const retailInstance = axios.create();
retailInstance.defaults.baseURL = retailURL;
retailInstance.defaults.method = "POST";
retailInstance.defaults.headers.post["Content-Type"] = "application/json";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const generateToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      console.error("No refresh token found in localStorage.");
      window.location.assign("/sign-in");
      return;
    }

    if (!refreshTokenURL) {
      console.error("refreshTokenURL is undefined!");
      window.location.assign("/sign-in");
      return;
    }

    const res = await axios.post(refreshTokenURL, { refreshToken }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      console.log("Token refreshed successfully:", res.data);

      let d = new Date();
      d.setTime(d.getTime() + 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      let domain = `domain=${domainURL}`;

      document.cookie = `Enterprise.accessToken=${res.data.accessToken}; ${expires}; path=/; secure=true; SameSite=Strict; ${domain}`;
      document.cookie = `Enterprise.refreshToken=${res.data.refreshToken}; ${expires}; path=/; secure=true; SameSite=Strict; ${domain}`;

    } else {
      console.warn("Unexpected response:", res);
      window.location.assign("/sign-in");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    window.location.assign("/sign-in");
  }
};


retailInstance.interceptors.request.use(
  (config) => {
    const authHeaders = getOAuthHeaders() || {}; // Fetch token dynamically
    const { access_token } = authHeaders;

    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

retailInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { message } = JSON.parse(JSON.stringify(error));
    if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
      generateToken();
    } else {
      return Promise.reject(error);
    }
  }
);

const syncTillData = async (userData) => {
  try {
    const data = {
      query: `query {
            tillData(user: "${userData}") {
              posScanConfigs {
                cwrPosScanConfigId
                scanTrxType
                dataType
                barcodeLength
                startsWith
                endsWith
                customFormula
                calculateTax
                formula
                delimiter
                multiProducts
              }
              loyaltyApply {
                cwrLoyaltyLevelId
                name
                applicableFor
                prodCategories {
                  mProductCategoryId
                  include
                }
              }
              tillAccess {
                cwrTillaccessId
                csClientId
                csUserId
                userAccessController
                cashManagement
                productSync
                salesHistory
                parkedBills
                giftCard
                manualDiscount
                layAway
                dataSyncMonitor
                unlinkTill
                salesReport
                holdBill
                couponCode
                tillAccessMeta {
                  key
                  value
                }
                cwrTill {
                  cwrTillID
                  searchKey
                  till
                  description
                  nextAssignedNumber
                  prefix
                  suffix
                  loyaltyProgram
                  accessController
                  posType
                  manageCash
                  showopeningamount
                  showsalesamount
                  showexpectedamount
                  showdifferenceamount
                  shiftclose
                  shiftopen
                  eReceipt
                  printPreview
                  cashin
                  cashout
                  layAway
                  payNow
                  calculateTax
                  cashToKeep
                  hardwareController {
                    imageUrl
                    printReceipt
                    weighingScale
                    payment
                    printBarcode
                  }
                  pHWController {
                    imageUrl
                    printReceipt
                    weighingScale
                    payment
                    printBarcode
                  }
                  printTemplate {
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                    obController
                    cwController
                  }
                  tillCloseTemplate {
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                    obController
                  }
                  kotPrintTemplate {
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                  }
                  cancelKotPrintTemplate {
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                  }
                  salesReportTemplate {
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                    obController
                  }
                }
                csBunit {
                  csBunitId
                  name
                  value
                  cwrSCustomerId
                  cwrCsDoctypeId
                  cwrPcatalogueId
                  isTaxIncluded
                  allowNegativeStock
                  cwrSpricelistId
                  pCatalogueSaleType {
                    cwrPcatalogueSaletypeId
                    isPromoApplicable
                    cwrSaletypeId
                  }
                  currencies {
                    csCurrencyId
                    currSymbol
                    isoCode
                    prcPrecision
                    stdPrecision
                    cstgPrecision
                    symbolRightSide
                    denominations {
                      value
                      seqNo
                    }
                    conversions {
                      csCurrencyIDTo
                      currencyFromRate
                      currencyToRate
                      validfromDate
                      validtoDate
                      isoCode
                    }
                  }
                  b2cCustomer {
                    cwrCustomerId
                    code
                    name
                    email
                    mobileNo
                    pincode
                    retlLoyaltyBalance
                    b2cRegisteredstoreId
                    iscredit
                    balancePoints
                    loyaltyLevel {
                      cwrLoyaltyLevelId
                      name
                      accumulationRate
                      redemptionRate
                    }
                    sCustomer {
                      sCustomerID
                      customerCategory {
                        sCustomerCateforyId
                        value
                        name
                        description
                      }
                    }
                  }
                  paymentMethodList {
                    roundOffForPos
                    cWRPaymentMethodID
                    sequenceNo
                    finPaymentmethodId
                    finFinancialAccountId
                    finDayCloseAccountId
                    name
                    integratedPayment
                    isloyalty
                    paymentProvider
                    iscredit
                    isGiftCard
                    isDefault
                    csCurrencyId
                    isoCode
                    isactive
                    cwrTillId
                    saleTypeName
                    saleTypeId
                    paymentProviderDetails {
                      cwrPaymentProviderId
                      providerName
                      providerType
                      providerCode
                      tid
                      status
                      providerConfigurations {
                        isSensitive
                        configValue
                        configKey
                      }
                    }
                    salesType {
                      cwrSaletypeId
                      name
                    }
                  }
                  mWarehouse {
                    mWarehouseID
                    name
                  }
                  customerAddress {
                    sCustomerAddressID
                    line1
                    line2
                    line3
                    fulladdress
                    phone
                    city
                    postalcode
                    csCountry {
                      csCountryID
                      name
                    }
                    csRegion {
                      csRegionID
                      name
                    }
                  }
                  locations {
                    csBunitLocationId
                    fulladdress
                    phone
                    contactPerson
                  }
                  salesRep {
                    code
                    name
                    salesRepresentId
                  }
                }
              }
              returnReasons {
                name
                value
                returnReasonId
              }
              status
              message
            }
          }`,
    };

    const resData = await retailInstance({
      data: data,
    });

    let tillData = resData?.data?.data?.tillData;

    // Check for successful status
    if (tillData?.status === "200") {
      tillData.time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      localStorage.setItem("tillData", JSON.stringify(tillData));
      return true;
    } else {
      throw new Error(tillData?.message || "Failed to fetch Till data");
    }
  } catch (error) {
    console.error("Error syncing till data:", error.message);
    return false;
  }
};

const getStoreOps = async (id) => {
  try {
    const queryPayload = {
      query: `query {
                getStoreOps(storeId: "${id}", date: "${moment().format("YYYY-MM-DD HH:mm:ss")}") {
                    status
                }
            }`,
    };

    const response = await retailInstance({
      data: queryPayload,
    });

    return response;
  } catch (error) {
    console.error("Error in getStoreOps:", error);
  }
};

const tillRegistration = async (syncId, cwrTillID, search_key, timeMark, username) => {
  try {
    const queryPayload = {
      query: `mutation {
            tillRegistration(uniqueId: "${syncId}", tillId: "${cwrTillID}", storeOpsTillId: null,
              till: {
                searchKey: "${search_key}"
                created: "${timeMark}"
                updated: "${timeMark}"
                tillAccess: { csUser: { username: "${username}" } }
                tillHistory: {
                  domain: null
                  sessionid: null
                  osVersion: null
                  localIpAddress: null
                  macAddress: null
                  processor: null
                  ram: null
                  regTimestamp: "${timeMark}"
                }
              }
            ) {
              status
              message
              searchKey
              tillAccess {
                salesRep
                salesRepId
                csClientId
                csUser {
                  csUserId
                }
                cwrTill {
                  till
                  searchKey
                  description
                  nextAssignedNumber
                }
                csBunit {
                  csBunitId
                  name
                  cwrSCustomerId
                  csCurrency {
                    csCurrencyId
                    currSymbol
                    isoCode
                    stdPrecision
                    cstgPrecision
                    prcPrecision
                  }
                }
              }
              tillHistory {
                domain
                sessionid
                osVersion
                localIpAddress
                macAddress
                processor
                ram
                regTimestamp
                cwrTillRegHistoryId
              }
            }
          }`,
    };

    const response = await retailInstance({
      data: queryPayload,
    });

    return response;
  } catch (error) {
    console.error("Error in tillRegistration:", error);
  }
};

const posConfigService = async (cwrTillID) => {
  try {
    const queryPayload = {
      query: `
              query {
                getPOSConfig(tillId: "${cwrTillID}", name: null) {
                  cwrPosConfigId
                  name
                  posType
                  application
                  configJson
                  PricingRule
                  ExpiryDiscount
                  activateExpiryDiscount
                  registrationTypes
                  feedback
                }
              }`,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.error("Error in posConfigService:", error);
  }
};

const getTills = async (userid) => {
  try {
    const queryPayload = {
      query: `query {
            getTills(name: null userId: "${userid}") {
              cwrTillID
              till
              searchKey
              cSBunitID
              status
              name
              cSUserId
              userName
              tillHistory {
                cwrTillRegHistoryId
                regTimestamp
                ram
                processor
                sessionid
                macAddress
                domain
                localIpAddress
                csBunitId
              }
            }
          }`,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.error("Error in getTills:", error);
  }
};

const upsertTill = async (newStringRequest) => {
  try {
    const queryPayload = {
      query: `mutation{
            upsertTill(tillInfo:${newStringRequest})
            {    
              status
              message
              cwrTillID
              tillCash {
                cwrTillCashId
                date
                finPaymentmethodId
                opening
                sales
                notes
                netsales
                cashin
                cashout
                retainAmt
                closing
                returns
                iscash
                isclose
                storeDailyOpsTillid
                cashEvents {
                  cwrCashEventsID
                  amount
                  expected
                  diff
                  transactionCount
                  type
                  description
                  cashEventDetails {
                    cwrCashEventdetailsID
                    count
                    amount
                    denomination
                  }
                }
              }
            }
          }`,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.error("Error in upsertTill", error);
  }
};

const unlinkTill = async (id) => {
  try {
    const queryPayload = {
      query: `mutation {
                    unlinkTill(tillId: "${id}") {
                      status
                      message
                    } 
                  }`,
    };
    const response = await retailInstance({ data: queryPayload });
    return response;
  } catch (error) {
    console.error("Error in unlinkTill", error);
  }
};

const upsertPOSActivity = async (tillData) => {
  try {
    const queryPayload = {
      query: `mutation {
                                            upsertPOSActivity(tillActivity: [
                                              {
                                                csBunitId: "${tillData.tillAccess[0].csBunit.csBunitId}"
                                                csUserId: "${tillData.tillAccess[0].csUser.csUserId}"
                                                tillRegistrationId: "${tillData.tillHistory[0].cwrTillRegHistoryId}"
                                                type: "LI"
                                                time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                                              }
                                            ]) {
                                              status
                                              message
                                            }
                                        }`,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.error("Error in upsertPOSActivity", error);
  }
};

const upsertStoreOps = async (id, userId) => {
  try {
    const mutationPayload = {
      query: `mutation {
                           upsertStoreOps(storeOps: {
                             cSBunitID: "${id}"
                             date: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                             status: "OP"
                             closedBy: null
                             closeTime: null
                             openedBy: "${userId}"
                             openTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                             remarks: null
                           }) {
                             status   
                             message
                           }
                         }`,
    };

    const response = await retailInstance({
      data: mutationPayload,
    });

    return response;
  } catch (error) {
    console.error("Error in upsertStoreOps", error);
  }
};

const upsertLoyaltyTransaction = async (tillData, value, cwrCustomerId, referenceId) => {
  try {
    const queryPayload = {
      query: `mutation {
            upsertLoyaltyTransaction(transaction: [{
                csBunitId: "${tillData.tillAccess.csBunit.csBunitId}"
                loyaltyPoints: ${value}
                b2cCustomerId: "${cwrCustomerId}"
                referenceId: "${referenceId}"
              }]) {
              status 
              message
            }
          }`,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.log(error, "loyality transaction error");
  }
};

const verifyCredit = async (cwrCustomerId) => {
  try {
    const queryPayload = {
      query: `query{
            verifyCredit(customerId:"${cwrCustomerId}"){
                iscredit
                creditLimit
            }
        }`,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.log(error, "verify credit error");
  }
};

const discardGiftCard = async (cardNo, refNo, array = null) => {
  try {
    const giftCards = array
      ? JSON.stringify(array).replace(/"([^"]+)":/g, "$1:") // Convert array to GraphQL format
      : `[{ cardNo: "${cardNo}", referenceNo: "${refNo}" }]`;

    const queryPayload = {
      query: `mutation{
            discardGiftCard(giftCards: ${giftCards}) {
                status
                message
            }
        }`,
    };

    const response = await retailInstance({ data: queryPayload });
    return response;
  } catch (error) {
    console.log(error, "discard gift card error");
  }
};

const discardCouponService = async (couponInput, referenceId) => {
  try {
    const queryPayload = {
      query: `query{
            discardCoupon(couponcode:"${couponInput}",referenceId: "${referenceId}"){
                status
                message
            }
        }`,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.log(error, "discard coupon error");
  }
};

const createCustomerService = async (customerInput) => {
  try {
    const response = await retailInstance({
      data: customerInput,
    });
    return response;
  } catch (error) {
    console.log(error, "create customer error");
  }
};

const updateCustomerService = async (customerInput) => {
  try {
    const response = await retailInstance({
      data: customerInput,
    });
    return response;
  } catch (error) {
    console.log(error, "update customer error");
  }
};

const getCustomerService = async (customerInput) => {
  try {
    const response = await retailInstance({
      data: customerInput,
    });
    return response;
  } catch (error) {
    console.log(error, "get customer error");
  }
};

const verifyGiftCardService = async (giftCardInput) => {
  try {
    const response = await retailInstance({
      data: giftCardInput,
    });
    return response;
  } catch (error) {
    console.log(error, "verify gift card error");
  }
};

const checkGiftCard = async (giftCardInput) => {
  try {
    const response = await retailInstance({
      data: giftCardInput,
    });
    return response;
  } catch (error) {
    console.log(error, "check gift card error");
  }
};

const upsertGiftCardTransaction = async (giftCardTransactionInput) => {
  try {
    const response = await retailInstance({
      data: giftCardTransactionInput,
    });
    return response;
  } catch (error) {
    console.error("Error in upsertGiftCardTransaction:", error);
  }
};

const salesHistoryService = async (salesHistoryInput) => {
  try {
    const response = await retailInstance({
      data: salesHistoryInput,
    });
    return response;
  } catch (error) {
    console.log(error, "sales history error");
  }
};

const syncAllProductCategories = (tillId, syncId) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
    try {
      const paramsInput = {
        query: `query{
            getProductCategory(tillId:"${tillId}", lastSyncTime: ${lastUpdatedTime ? `"${lastUpdatedTime}"` : null}, timeZone:"${timeZone}"){
                mProductCategoryId
                name
                value
                description
                imageurl
                parentCategory{
                    mProductCategoryId
                    name
                    value
                    description
                    imageurl
                    parentCategory{
                        mProductCategoryId
                        name
                        value
                        description
                        imageurl
                        parentCategory{
                            mProductCategoryId
                            name
                            value
                            description
                            imageurl
                            parentCategory{
                                mProductCategoryId
                                name
                                value
                                description
                                imageurl
                            }
                        }
                    }
                }
            }
          }`,
      };

      const response = await retailInstance({
        data: paramsInput,
      });

      if (response.status === 200) {
        const { getProductCategory } = response.data.data;

        if (getProductCategory && getProductCategory.length > 0) {
          console.log("POS Sale Types: ", "Synced");

          const isArray = [];
          const finalArray = [];
          const seenNames = new Set();

          function getParentCategoryValue(obj) {
            if (!obj.parentCategory) {
              const id = obj.mProductCategoryId;
              if (!seenNames.has(id)) {
                isArray.push(obj);
                seenNames.add(id);
              }
              return id;
            }
            obj.id = obj.parentCategory.mProductCategoryId;
            finalArray.push({
              mProductCategoryId: obj.mProductCategoryId,
              name: obj.name,
              value: obj.value,
              description: obj.description,
              imageurl: obj.imageurl,
              id: obj.id,
              syncId: syncId,
            });

            return getParentCategoryValue(obj.parentCategory);
          }

          for (const obj of getProductCategory) {
            getParentCategoryValue(obj);
          }

          const seenId = new Set();
          isArray.forEach((item) => {
            const printArray = finalArray.reduce((result, current) => {
              const category = current.id;
              if (!result[category]) {
                result[category] = [];
              }
              const id = current.mProductCategoryId;
              if (!seenId.has(id)) {
                seenId.add(id);
                result[category].push(current);
              }
              return result;
            }, {});
            item.printArray = printArray;
          });

          const lastId = await db.AllProductCategories.bulkPut(isArray);
          posSaleTypeSyncSuccess(lastId);
        } else {
          posSaleTypeSyncSuccess();
        }
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      posSaleTypeSyncFailure(error);
    }
  });
};

const syncProductCategories = (tillData, tillId, syncId) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  localStorage.setItem("sync1", true);
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  return new Promise(async (productCategorySyncSuccess, productCategorySyncFailure) => {
    try {
      const paramsInput = {
        query: `query {
              productCategory(bunit:"${tillData.tillAccess.csBunit.csBunitId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        },uniqueId: "${syncId}",tillId:"${tillId}", storeOpsTillId: null, timeZone:"${timeZone}") {
              mProductCategoryId
              value
              name
              description
              imageurl
              }
            }`,
      };

      const response = await retailInstance({
        data: paramsInput,
      });

      const { productCategory } = response.data.data;

      if (response.status === 200 && productCategory.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");

          await Promise.all(
            productCategory.map(async (item) => {
              await db.productCategories
                .where("mProductCategoryId")
                .equals(item.mProductCategoryId)
                .toArray()
                .then(async (response) => {
                  if (response.length > 0) {
                    item.syncId = syncId;
                    await db.productCategories.update(item.mProductCategoryId, item);
                    recordsData = recordsData + 1;
                  } else {
                    item.syncId = syncId;
                    await db.productCategories.add(item);
                    recordsData = recordsData + 1;
                  }
                });
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, categories: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  categories: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.categories },
                };
              });
          }
          await syncProductBrand(syncId, tillId);
        } else {
          productCategory.forEach((item) => {
            item.syncId = syncId;
          });
          const lastId = await db.productCategories.bulkPut(productCategory);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, categories: { syncStartTime: syncStartTime, syncEndTime, records: productCategory.length } };
              event.records = (event.records ? event.records : 0) + productCategory.length;
            });
          console.log("Product Category: ", "Synced");
          productCategorySyncSuccess(lastId);
        }
        // Ensure syncProductBrand is called after successful product category sync
        await syncProductBrand(syncId, tillId);
        productCategorySyncSuccess();
      } else {
        productCategorySyncSuccess();
        await syncProductBrand(syncId, tillId);
      }
    } catch (error) {
      console.log("Product Category: ", "Sync Failed");
      productCategorySyncFailure(error);
    }
  });
};

const syncProductBrand = async (syncId, tillId) => {
  localStorage.setItem("sync1", true);
  // await db.productBrands.clear();
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  const userData = JSON.parse(localStorage.getItem("userData"));
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  if (!userData || !userData.cs_client_id) {
    console.error("User data or client ID is missing.");
    return Promise.reject("User data or client ID is missing.");
  }

  const paramsInput = {
    query: `query {
        getBrand(clientId: "${userData.cs_client_id}", lastSyncTime: ${
      lastUpdatedTime ? `"${lastUpdatedTime}"` : null
    },uniqueId: "${syncId}", tillId:"${tillId}", storeOpsTillId: null, timeZone:"${timeZone}") {
          brandId
          name
          value
        }
      }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await retailInstance({
        data: paramsInput,
      });

      const { getBrand } = response.data.data;
      if (response.status === 200 && getBrand && getBrand.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
          await Promise.all(
            getBrand.map(async (item) => {
              const existingBrand = await db.productBrands.where("brandId").equals(item.brandId).first();
              // const existingBrand = await db.productBrands.where("brandId").equals(item.brandId).toArray();
              if (existingBrand) {
                // console.log(existingBrand, "-------->existing");
                recordsData = recordsData + 1;
                item.syncId = syncId;
                await db.productBrands.update(item.brandId, item);
                // console.log(`Brand with ID ${item.brandId} updated.`);
              } else {
                try {
                  item.syncId = syncId;
                  recordsData = recordsData + 1;
                  await db.productBrands.add(item);
                  // console.log(`New brand with ID ${item.brandId} added.`);
                } catch (error) {
                  console.error(`Failed to add new brand with ID ${item.brandId}: `, error);
                }
              }
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, brands: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  brands: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.brands },
                };
              });
          }
        } else {
          getBrand.forEach((item) => {
            item.syncId = syncId;
          });
          await db.productBrands.bulkAdd(getBrand);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, brands: { syncStartTime: syncStartTime, syncEndTime, records: getBrand.length } };
              event.records = (event.records ? event.records : 0) + getBrand.length;
            });
        }
        console.log("Product Brand: ", "Synced");
        resolve(); // Resolve the Promise after successful execution
      } else {
        console.log("Product Brand: ", "No new brands to sync");
        resolve(); // Resolve the Promise if there are no brands to sync
      }
    } catch (error) {
      console.error("Product Brand Sync Failed: ", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

const syncPosSaleTypes = async (syncId, tillId, tillData) => {
  const lastUpdatedTime = null;
  await db.posSaletypes.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
    try {
      const paramsInput = {
        query: `query{
            getPosSaletype(tillId:"${tillId}",catalogueId:"${tillData.tillAccess.csBunit.cwrPcatalogueId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        },uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}"){
              cwrPcatalogueSaletypeId
              cSClientID
              cSBunitID
              created
              createdby
              updated
              updatedby
              isactive
              isPromoApplicable
              cwrPCatalogueId
              cwrSaletype{
                cwrSaletypeId
                value
                name
                isdefault
                colourCode
                enableLayaway
              }
              customer{
                    b2cCustomerId
                    code
                    name
                    email
                    mobileNo
                    pincode
                    retlLoyaltyBalance
                    b2cRegisteredstoreId
                    iscredit
                    balancePoints
                    sPriceListId
                    priceListName
                    cwrCustomerId
                    sCustomerId
                }
            }
          }`,
      };

      const response = await retailInstance({
        data: paramsInput,
      });

      const { getPosSaletype } = response.data.data;

      if (response.status === 200 && getPosSaletype.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            getPosSaletype.map(async (item) => {
              item.customer.sCustomer = { sCustomerID: item.customer.sCustomerId };
              item.syncId = syncId;
              await db.posSaletypes
                .where("cwrSaletypeId")
                .equals(item.cwrSaletypeId)
                .toArray()
                .then(async (response) => {
                  if (response.length > 0) {
                    recordsData = recordsData + 1;
                    await db.posSaletypes.update(item.cwrSaletypeId, item);
                  } else {
                    recordsData = recordsData + 1;
                    await db.posSaletypes.add(item);
                  }
                });
            })
          );

          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, posSaletypes: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  posSaletypes: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.posSaletypes },
                };
              });
          }
        } else {
          getPosSaletype.forEach((item) => {
            item.customer.sCustomer = { sCustomerID: item.customer.sCustomerId };
            item.syncId = syncId;
          });
          const lastId = await db.posSaletypes.bulkPut(getPosSaletype);
          console.log("POS Sale Types: ", "Synced");
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, posSaletypes: { syncStartTime: syncStartTime, syncEndTime, records: getPosSaletype.length } };
              event.records = (event.records ? event.records : 0) + getPosSaletype.length;
            });
          posSaleTypeSyncSuccess(lastId);
        }
      } else {
        posSaleTypeSyncSuccess();
      }
    } catch (error) {
      console.log("POS Sale Types: ", "Sync Failed");
      posSaleTypeSyncFailure(error);
    }
  });
};

const syncProductsUom = async (syncId, tillId) => {
  const lastUpdatedTime = null;
  await db.productUom.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  return new Promise(async (uomSyncSuccess, uomSyncFailure) => {
    try {
      const paramsInput = {
        query: `query {
            getUom(lastSyncTime: ${lastUpdatedTime ? `"${lastUpdatedTime}"` : null},uniqueId: "${syncId}", tillId:"${tillId}", storeOpsTillId: null,timeZone:"${timeZone}") {
              csUomId
              costingprecision
              description
              ediCode
              name
              stdprecision
              symbol
              decimal
            }
          }`,
      };

      const response = await retailInstance({
        data: paramsInput,
      });

      const { getUom } = response.data.data;

      if (response.status === 200 && getUom.length > 0) {
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            getUom.map(async (item) => {
              item.syncId = syncId;
              const existingUom = await db.productUom.where("csUomId").equals(item.csUomId).toArray();
              if (existingUom.length > 0) {
                recordsData = recordsData + 1;
                await db.productUom.update(item.csUomId, item);
              } else {
                recordsData = recordsData + 1;
                await db.productUom.add(item);
              }
            })
          );
          let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, UOM: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = { ...event.type, UOM: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.UOM } };
              });
          }
        } else {
          getUom.forEach((uom) => {
            uom.syncId = syncId;
          });
          const lastId = await db.productUom.bulkPut(getUom);
          let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, UOM: { syncStartTime: syncStartTime, syncEndTime, records: getUom.length } };
              event.records = (event.records ? event.records : 0) + getUom.length;
            });
          console.log("POS Uom: ", "Synced");
          uomSyncSuccess(lastId);
        }
        uomSyncSuccess();
      } else {
        console.log("POS Uom: ", getUom);
        uomSyncSuccess();
      }
    } catch (error) {
      console.log("POS Uom: Sync Failed", error);
      uomSyncFailure(error);
    }
  });
};

const syncProducts = async (tillData, syncId, tillId) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  let recordsCount = 0;
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  try {
    let from = 0;
    let limit = 5000;
    let productsSynced = false;
    while (!productsSynced) {
      const paramsInput = {
        query: `query {
                  getProducts1(bUnit:"${tillData.tillAccess.csBunit.csBunitId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        }, from:${from} limit:${limit},tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null,timeZone:"${timeZone}") {
                    mProductId
                    csClientId
                    csBunitId
                    created
                    createdby
                    updated
                    isactive
                    updatedby
                    sunitprice
                    slistprice
                    onhandQty
                    description
                    isVirtual
                    isBestSeller
                    cTaxId
                    taxRate
                    taxName
                    isPromoApplicable
                    isavailable
                    ismonday
                    istuesday
                    iswednesday
                    isthursday
                    isfriday
                    issaturday
                    issunday
                    colorcode
                    productionCenter
                    cwrMenuTimeslot {
                      cwrMenuTimeslotId
                      name
                      startTime
                      endTime
                    }
                    mProduct {
                      value
                      name
                      name2
                      type
                      csTaxcategoryId
                      mProductCategoryId
                      csUomId
                      uomName
                      upc
                      batchedProduct
                      isManualQty
                      isDecimal
                      imageurl
                      shortDescription
                      hsncode
                      returnable
                      returnDays
                      description
                      batchedForSale
                      batchedForStock
                      multiPrice
                      addNewLine
                      shelfLife
                      isBag
                      showAddons
                      foodType
                      productSegment
                      taxCategory {
                        csTaxcategoryID
                        name
                        overRideTax
                        overRideCondition
                        contraTaxCategoryId
                        contraTaxCategory
                        {
                          contraTaxCategoryName
                          contraTaxId
                          contraTaxName
                          contraRate
                        }
                      }
                      mBatch {
                        mBatchId
                        batchno
                        upc
                        price
                        listPrice
                        startdate
                        enddate
                        life
                      }
                      productGroup {
                        mProductGroupId
                        name
                        value
                        description
                      }
                      productAddons {
                        mProductAddOnId
                        name
                        price
                        mAddonGroup {
                          mAddonGroupId
                          name
                          maxqty
                          minqty
                          type
                        }
                        mAddonProduct {
                          mProductId
                          name
                        }
                      }
  
                      productAttributes {
                        mProductAttributeId
                        value
                        mAttributeGroup {
                          mAttributeGroupId
                          name
                        }
                        mAttribute {
                          mAttributeId
                          name
                        }
                      }
  
                      productManufacturer {
                        mProductManufacturerId
                        name
                        value
                        description
                      }
                      productBrand {
                        brandId
                        name
                        value
                        description
                      }
                      priceLists {
                          pricelist
                         pricestd
                          sPricelistId
                         sPriceList
                      }
                    customAttributes{
                      name
                        fileds {
                            name
                            type
                            values
                            defaultValue
                            mandatory
                        }
                      }
                    }
                  }
                }`,
      };
      from += 5000;
      const response = await retailInstance({
        data: paramsInput,
      });

      const { getProducts1 } = response.data.data;
      const products = getProducts1;
      const productsLength = products.length;
      if (productsLength > 0) {
        recordsCount = recordsCount + productsLength;
        const productsData = [];
        const brands = new Set();
        const categories = new Set();
        for (let i = 0; i < productsLength; i += 1) {
          const upcIndex = [];
          const batchIndex = [];
          if (products[i].mProduct.mBatch !== null) {
            for (let j = 0; j < products[i].mProduct.mBatch.length; j += 1) {
              batchIndex.push(products[i].mProduct.mBatch[j].batchno);
              upcIndex.push(products[i].mProduct.mBatch[j].upc);
            }
          }
          if (products[i].mProduct.upc !== null) {
            upcIndex.push(products[i].mProduct.upc);
          }
          let uomData = [];
          await db.productUom
            .where("csUomId")
            .equals(products[i].mProduct.csUomId)
            .toArray()
            .then((uom) => {
              if (uom.length > 0) {
                uomData = uom;
              }
            });
          const productDataObj = {
            mProductId: products[i].mProductId || "",
            csClientId: products[i].csClientId || "",
            csBunitId: products[i].csBunitId || "",
            created: products[i].created || "",
            createdby: products[i].createdby || "",
            updated: products[i].updated || "",
            isactive: products[i].isactive || "",
            productionCenter: products[i].productionCenter || "",
            updatedby: products[i].updatedby || "",
            sunitprice: products[i].sunitprice || 0,
            addNewLine: products[i].mProduct.addNewLine || "N",
            slistprice: products[i].slistprice || 0,
            onhandQty: products[i].onhandQty || 0,
            description: products[i].description || "",
            shortDescription: products[i].mProduct.shortDescription || "",
            isVirtual: products[i].isVirtual || "",
            isBestSeller: products[i].isBestSeller || "",
            cTaxId: products[i].cTaxId || "",
            taxRate: products[i].taxRate || 0,
            isPromoApplicable: products[i].isPromoApplicable || "",
            value: products[i].mProduct.value || "",
            showAddons: products[i].mProduct.showAddons || "N",
            foodType: products[i].mProduct.foodType || "",
            name: products[i].mProduct.name || "",
            name2: products[i].mProduct.name2 || "",
            brandId: products[i].mProduct.productBrand.brandId || "",
            csTaxcategoryId: products[i].mProduct.csTaxcategoryId || "",
            mProductCategoryId: products[i].mProduct.mProductCategoryId || "",
            csUomId: products[i].mProduct.csUomId || "",
            uomName: products[i].mProduct.uomName || "",
            uomData: uomData || "",
            upc: products[i].mProduct.upc || "",
            batchedProduct: products[i].mProduct.batchedProduct || "",
            batchedForSale: products[i].mProduct.batchedForSale || "",
            batchedForStock: products[i].mProduct.batchedForStock || "",
            isManualQty: products[i].mProduct.isManualQty || "",
            isDecimal: products[i].mProduct.isDecimal || "",
            imageurl: products[i].mProduct.imageurl || "",
            productSegment: products[i].mProduct?.productSegment ? products[i].mProduct.productSegment : "",
            taxCategory: products[i].mProduct.taxCategory.name || "",
            taxName: products[i].taxName || "",
            taxFlag: "N",
            mBatch: products[i].mProduct.mBatch || "",
            hsncode: products[i].mProduct.hsncode || "",
            batchIndex: batchIndex || "",
            upcIndex: upcIndex || "",
            customAttributes: products[i].mProduct?.customAttributes?.fileds || [],
            type: products[i].mProduct.type || "",
            priceList: products[i].mProduct.priceLists,
            productManufacturerName: products[i].mProduct.productManufacturer.name || "",
            productManufacturerId: products[i].mProduct.productManufacturer.mProductManufacturerId || "",
            productBrandName: products[i].mProduct.productBrand.name || "",
            productBrandId: products[i].mProduct.productBrand.brandId || "",
            iscustomizable: products[i].mProduct?.iscustomizable || "",
            mProductGroupId: products[i]?.mProduct?.productGroup?.mProductGroupId || "",
            productCategoryName: products[i]?.mProduct?.productGroup?.name || "",
            productAddons: products[i]?.mProduct?.productAddons || [],
            multiPrice: products[i].mProduct.multiPrice || "",
            shelfLife: products[i].mProduct.shelfLife || "",
            overRideTax: products[i].mProduct.taxCategory.overRideTax || "",
            overRideCondition: parseFloat(products[i].mProduct.taxCategory.overRideCondition) || "",
            contraRate: parseFloat(products[i].mProduct.taxCategory.contraTaxCategory?.contraRate) || "",
            contraTaxId: products[i].mProduct.taxCategory.contraTaxCategory?.contraTaxId || "",
            syncId: syncId,
          };
          brands.add(productDataObj.brandId);
          categories.add(productDataObj.mProductCategoryId);
          productsData.push(productDataObj);
        }
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime !== null) {
          let recordsData = 0;
          await Promise.all(
            productsData.map(async (item) => {
              await db.products
                .where("value")
                .equals(item.value)
                .toArray()
                .then(async (response) => {
                  if (response.length > 0) {
                    item.syncId = syncId;
                    recordsData = recordsData + 1;
                    await db.products.where("value").equals(item.value).modify(item);
                  } else {
                    item.syncId = syncId;
                    await db.products.add(item);
                    recordsData = recordsData + 1;
                  }
                });
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = {
                ...event.type,
                products: { syncStartTime: syncStartTime, syncEndTime, records: event?.products?.records ? event?.products?.records + recordsData : recordsData },
              };
              event.records = (event?.records ? event?.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  products: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type?.products },
                };
              });
          }
        } else {
          const lastId = await db.products.bulkPut(productsData);
          console.log("Synced products count:", lastId);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, products: { syncStartTime: syncStartTime, syncEndTime, records: recordsCount } };
              event.records = (event?.records ? event?.records : 0) + products.length;
            });
        }
      } else {
        console.log("Products: Synced");
        productsSynced = true;
      }
    }
    // Code to be executed after syncProducts completion
  } catch (error) {
    console.log("Products: Sync Failed");
    throw error;
  }
};

const syncPricingRules = async (tillData, syncId, tillId) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  // await db.pricingRules.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  return new Promise(async (pricingRulesSyncSuccess) => {
    try {
      const paramsInput = {
        query: `query {
              getPricingRules(catalogueId: "${tillData.tillAccess.csBunit.cwrPcatalogueId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        },tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}"){
                  mPricingrulesId
                  csClientId
                  csBunitId
                  created
                  createdBy
                  updated
                  updatedBy
                  upc
                  type
                  discountType
                  name
                  printedName
                  description
                  startDate
                  endDate
                  nextRule
                  percentageDiscount
                  amountDiscount
                  minimumQty
                  maximumQty
                  xQty
                  yQty
                  billAmount
                  maxBillAmount
                  cwrSaletypeId
                  fixedUnitPrice
                  timeSpecific
                  starttime
                  endtime
                  monday
                  tuesday
                  wednesday
                  thursday
                  friday
                  saturday
                  sunday
                  status
                  iscoupon
                  nextRule
                  priority
                  giftVoucherType
                  issueGiftVoucher
                  manualDiscount
                  excludeProductCategories
                  excludeBusinessUnits
                  excludeProducts
                  excludeB2CCustomers
                  excludeBrands
                  excludeCustomers
                  excludeCustomerCategory
                  excludeB2CSegment
                  enableApproval
                  mPricingXProducts {
                      mPricingXProductId
                      line
                      mProductId
                      mBatchId
                      isFree
                      quantity
                  }
                  mPricingYProducts {
                      mPricingYProductId
                      line
                      mProductId
                  }
                  mPricingCcategories {
                      mPricingCcategoryId
                      line
                      sCustomerCategoryId
                  }
                  mPricingPcategories {
                      mPricingPcategoryId
                      line
                      mProductCategoryId
                  }
                  mPricingBrands {
                      mPricingBrandId
                      line
                      mBrandId
                  }
                  mPricingBUnits {
                      mPricingBUnitId
                      line
                      mBunitPricingId
                  }
                  mPricingQuantities {
                      mPricingQuantityId
                      line
                      quantity
                      discountValue
                  }
                  mPricingB2CCustomers {
                      mPricingB2cCustomerId
                      lineno
                      b2cCustomerId
                  }
                  mPricingB2CCustomerSegments {
                      mPricingB2CSegmentId
                      lineNo
                      cwrB2CCustomerSegmentId
                  }
                  mPricingExpiryDiscount {
                    rangeFrom
                    rangeTo
                    discountPercentage
                    mProductId
                }
                mPricingApprovals {
                    mPricingApprovalId
                    type
                    min
                    max
                    userId
                    roleId
                    userName
                    pin
                    role
                }
              }
          }`,
      };
      const response = await retailInstance({
        data: paramsInput,
      });

      const { getPricingRules } = response.data.data;
      if (response.status === 200 && getPricingRules.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            getPricingRules.map(async (item) => {
              const existingRule = await db.pricingRules.where("mPricingrulesId").equals(item.mPricingrulesId).first();

              if (existingRule) {
                await db.pricingRules.delete(existingRule.id);
                // Update existing pricing rule
                item.syncId = syncId;
                await db.pricingRules.add(item);
                recordsData = recordsData + 1;
              } else {
                item.syncId = syncId;
                // Add new pricing rule
                recordsData = recordsData + 1;
                await db.pricingRules.add(item);
              }
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, pricingRules: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  pricingRules: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.pricingRules },
                };
              });
          }
        } else {
          await db.pricingRules.clear();
          getPricingRules.forEach((item) => {
            item.syncId = syncId;
          });
          const lastId = await db.pricingRules.bulkPut(getPricingRules);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, pricingRules: { syncStartTime: syncStartTime, syncEndTime, records: getPricingRules.length } };
              event.records = (event.records ? event.records : 0) + getPricingRules.length;
            });
          console.log("Product Pricing Rules: ", " Synced");
          pricingRulesSyncSuccess(lastId);
        }
        pricingRulesSyncSuccess();
      } else {
        console.log("Product Pricing Rules: ", getPricingRules);
        pricingRulesSyncSuccess();
      }
    } catch (error) {
      console.log("Product Pricing Rules: ", " Sync Failed");
      pricingRulesSyncSuccess();
    }
  });
};

const syncOfflineDta = async (tillData) => {
  try {
    const events = await db.tillEvents
      .where("tillStatus")
      .equals("closed")
      .filter((event) => event.isSynced === 0)
      .toArray();

    if (events.length > 0) {
      for (let i = 0; i < events.length; i += 1) {
        const event = events[i];
        const tillSession = JSON.parse(localStorage.getItem("tillSession"));
        const tillSessionId = tillSession?.tillSessionId;

        await db.tillEvents
          .where("tillSessionId")
          .equals(event?.tillSessionId)
          .modify((event) => {
            if (tillSessionId !== event?.tillSessionId) {
              event.tillStatus = "closed";
              event.isSynced = 1;
            }
          });

        const paramsInput = {
          query: `mutation{
              upsertTill(tillInfo:${events[i].tillInfo})
              {    
                status
                message
                cwrTillID
                tillCash{
                  cwrTillCashId
                  date
                  finPaymentmethodId
                  opening
                  sales
                  netsales
                  cashin
                  cashout
                  retainAmt
                  closing
                  returns
                  iscash
                  notes
                  isclose
                  storeDailyOpsTillid
                  cashEvents{
                    cwrCashEventsID
                    amount
                    expected
                    diff
                    transactionCount
                    type
                    description
                    cashEventDetails{
                      cwrCashEventdetailsID
                      count
                      amount
                      denomination
                    }
                  }
                }
              }
            }`,
        };

        try {
          const response = await retailInstance({
            data: paramsInput,
          });

          const result = response.data.data?.upsertTill;
          const status = result?.status;
          if (status === "200") {
            localStorage.removeItem("storeDailyOpsTillid");

            let tillData = event.tillRegistration;
            const queryData = {
              query: `mutation {
                    upsertPOSActivity(tillActivity: [
                      {
                        csBunitId: "${tillData[0]?.tillAccess[0]?.csBunit?.csBunitId}"
                        csUserId: "${tillData[0]?.tillAccess[0]?.csUser?.csUserId}"
                        tillRegistrationId: "${tillData[0]?.tillHistory[0]?.cwrTillRegHistoryId}"
                        type: "LO"
                        time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                      }
                    ]) {
                      status
                      message
                    }
                }`,
            };
            await retailInstance({
              data: queryData,
            });
          }
        } catch (error) {
          console.error("Error syncing offline data:", error.message);
        }
      }
    }
  } catch (error) {
    console.error("Error fetching events from DB:", error.message);
  }
};

const syncPosConfigData = (syncId, tillId) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");

  return new Promise(async (resolve, reject) => {
    try {
      const paramsInput = {
        query: `query {
              getPOSConfig(tillId: "${tillId}", name: null, lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        }, uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}") {
                cwrPosConfigId
                name
                posType
                application
                configJson
                PricingRule
                ExpiryDiscount
                activateExpiryDiscount
                registrationTypes
                feedback
              }
            }`,
      };

      const response = await retailInstance({
        data: paramsInput,
      });

      const posConfig = response.data.data.getPOSConfig;
      const feedbackData = posConfig[0]?.feedback ? JSON.parse(posConfig[0]?.feedback) : [];

      if (posConfig.length > 0) {
        const posConfigData = JSON.parse(posConfig[0].configJson);
        const registrationTypes = JSON.parse(posConfig[0].registrationTypes) || [];
        localStorage.setItem("expiryDiscount", posConfig[0].activateExpiryDiscount);
        localStorage.setItem("posConfig", JSON.stringify(posConfigData));
        localStorage.setItem("registrationTypes", JSON.stringify(registrationTypes));
        localStorage.setItem("feedbackJson", JSON.stringify(feedbackData));
        console.log("POS Config: ", "Synced");
      } else {
        console.log("POS Config: No new config to sync");
      }
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("POS Config: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

const syncLoyalityData = async (syncId, tillId) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  const loyalityQuery = {
    query: `query {
        getLoyaltyconfigurations(lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        }, tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}") {
          loyaltylevelId
          name
          accumulationRate
          redemptionRate
          applicableFor
          isDefault
          levelId
          validityPeriod
          minPoints
          maxPoints
          sequence
          upgradeThreshold
          downgrade_threshold
          benefits
          LoyaltyAccumulation {
            loyaltyAccumulationId
            name
            description
            loyaltyLevelId
            pointsPerUnit
            mProductId
            product
            mProductCategoryId
            productCategory
            minPurchase
            startDate
            endDate
            loyaltyEvent
          }
          loyaltyRedemption {
            loyaltyRedemptionId
            ruleName
            description
            loyaltyLevelId
            pointsRequired
            redemptionValue
            minPurchase
            max_redemption
            startDate
            endDate
          }
          loyaltyEventCalenders {
            loyaltyEventCalenderId
            name
            description
            eventType
            startDate
            endDate
          }
        }
      }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await retailInstance({
        data: loyalityQuery,
      });
      const loyaltyConfigurations = response.data.data.getLoyaltyconfigurations;

      if (loyaltyConfigurations.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            loyaltyConfigurations.map(async (item) => {
              const existingLoyalty = await db.loyalityData.where("loyaltylevelId").equals(item.loyaltylevelId).toArray();
              if (existingLoyalty.length > 0) {
                item.syncId = syncId;
                recordsData = recordsData + 1;
                await db.loyalityData.update(item.loyaltylevelId, item);
              } else {
                item.syncId = syncId;
                recordsData = recordsData + 1;
                await db.loyalityData.add(item);
              }
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, loyalityData: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  loyalityData: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.loyalityData },
                };
              });
          }
        } else {
          loyaltyConfigurations.forEach((item) => {
            item.syncId = syncId;
          });
          await db.loyalityData.bulkPut(loyaltyConfigurations);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, loyalityData: { syncStartTime: syncStartTime, syncEndTime, records: loyaltyConfigurations.length } };
              event.records = (event.records ? event.records : 0) + loyaltyConfigurations.length;
            });
        }
        console.log("Loyalty Data: ", "Synced");
      } else {
        console.log("Loyalty Data: No new data to sync");
      }
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("Loyalty Data: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

const syncGiftCardData = async (syncId, tillId) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  const giftQuery = {
    query: `query {
        getGiftCardType(lastSyncTime: ${lastUpdatedTime ? `"${lastUpdatedTime}"` : null}, tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}") {
          cwrGiftcardTypeId
          name
          mProductId
          product
          type
          singleUse
          validity
          balanceLimit
          topupLimit
          prefix
          lengthCardNo
          pinRequired
          initialAmount
          giftCardGroup
          promotionEligible
          variableAmount
          printTemplate {
            cwrPrinttemplateId
            name
            htmlcode
            htmlcode2
            xmlcode
            xmlcode2
            obController
          }
        }
      }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await retailInstance({
        data: giftQuery,
      });

      const giftCardTypes = response.data.data.getGiftCardType;

      if (giftCardTypes.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            giftCardTypes.map(async (item) => {
              const existingGiftCard = await db.giftCardData.where("cwrGiftcardTypeId").equals(item.cwrGiftcardTypeId).toArray();
              if (existingGiftCard.length > 0) {
                recordsData = recordsData + 1;
                item.syncId = syncId;
                await db.giftCardData.update(item.cwrGiftcardTypeId, item);
              } else {
                item.syncId = syncId;
                await db.giftCardData.add(item);
                recordsData = recordsData + 1;
              }
            })
          );

          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = {
                ...event.type,
                giftCardData: { syncStartTime: syncStartTime, syncEndTime, records: recordsData },
              };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  giftCardData: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.giftCardData },
                };
              });
          }
        } else {
          giftCardTypes.forEach((item) => {
            item.syncId = syncId;
          });
          await db.giftCardData.bulkPut(giftCardTypes);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, giftCardData: { syncStartTime: syncStartTime, syncEndTime, records: giftCardTypes.length } };
              event.records = (event.records ? event.records : 0) + giftCardTypes.length;
            });
        }
        console.log("Gift Card Data: ", "Synced");
      } else {
        console.log("Gift Card Data: No new data to sync");
      }
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("Gift Card Data: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

const getApprovals = async () => {
  let tillId = JSON.parse(localStorage.getItem("tillValue")).cwr_till_id;
  let syncId = uuidv4().replace(/-/g, "").toUpperCase();
  await db.POSWorkFlowRules.clear();
  await db.approvers.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  try {
    const paramsInput = {
      query: `query {
            getPOSWorkflowRules(tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null,){
                rules{
                cwrRulesId
               ruleName
               ruleCondition
               rulePriority
                cwrEventId
               eventId
               eventName
               apiType
               apiSource
               apiEndPoint
               triggerType
               }
               approvers {
                   name
                   pin
                   csUserId
                   csBunitId
                   bunitName
                   role
               }
              }
          }`,
    };
    const response = await retailInstance({
      data: paramsInput,
    });
    console.log(response, "------->appr");
    if (
      response?.data?.data?.getPOSWorkflowRules?.rules !== null &&
      response?.data?.data?.getPOSWorkflowRules?.rules !== undefined &&
      response?.data?.data?.getPOSWorkflowRules?.rules !== ""
    ) {
      await db.POSWorkFlowRules.bulkPut(response?.data?.data?.getPOSWorkflowRules?.rules);
    }

    const approvers = response?.data?.data?.getPOSWorkflowRules?.approvers || [];

    if (approvers.length > 0) {
      // Generate unique ids for each approver
      const approversWithIds = approvers.map((approver) => ({
        ...approver,
        id: uuidv4(), // Generate unique id
        syncId: syncId,
      }));

      // Add approvers with unique ids to IndexedDB
      await db.approvers.bulkAdd(approversWithIds);
      let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      db.dataSyncSummary
        .where("syncId")
        .equals(syncId)
        .modify((event) => {
          event.type = { ...event.type, approvers: { syncStartTime: syncStartTime, syncEndTime, records: approversWithIds.length } };
          event.records = (event.records ? event.records : 0) + approversWithIds.length;
        });
      if (latestIncrementalSync?.length > 0) {
        db.dataSyncSummary
          .where("syncId")
          .equals(latestIncrementalSync[0].syncId)
          .modify((event) => {
            event.type = {
              ...event.type,
              approvers: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: approversWithIds.length, ...event.type.approvers },
            };
          });
      }
    }
  } catch (error) {
    console.log("Approvals: Sync Failed");
    throw error;
  }
};

const syncRestaurantTables = async () => {
  try {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const tillId = tillData?.tillAccess?.cwrTill?.cwrTillID;

    if (!tillId) throw new Error("Till ID is not available");

    // Fetch restaurant tables data
    const getRestaurantTables = await fetchRestaurantTables(tillId);
    if (!getRestaurantTables || getRestaurantTables?.length === 0) {
      // throw new Error("No restaurant tables found");
    }

    // Process restaurant tables
    const { tempData, sectionData } = processRestaurantTables(getRestaurantTables);

    // Store section data
    await db.sectionTables.clear();
    await db.sectionTables.bulkAdd(sectionData);

    // Fetch occupied tables data
    const occupiedData = await fetchOccupiedTablesData();

    // Update table data with occupied information
    updateTableDataWithOccupiedInfo(tempData, occupiedData);

    // Store updated table data
    await db.tableData.clear();
    await db.tableData.bulkPut(tempData);

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

// Function to fetch restaurant tables from the server
const fetchRestaurantTables = async (tillId) => {
  try {
    const query = `query {
      getRestaurantTables(tillId: "${tillId}") {
        cwrFbsectionId
        sectionName
        posTables {
          cwrFbTableId
          cSBunitID
          cSClientID
          created
          createdby
          csWindowId
          isactive
          updated
          updatedby
          name
          capacity
          cwrFbFloorId
        }
      }
    }`;

    const response = await retailInstance({
      data: { query },
    });

    return response?.data?.data?.getRestaurantTables;
  } catch (error) {
    console.log("Error in fetchRestaurant tables", error);
  }
};

// Function to process restaurant tables data
const processRestaurantTables = (getRestaurantTables) => {
  let tempData = [];
  let sectionData = [{ value: "all", name: "All" }];

  getRestaurantTables.forEach((section, index) => {
    section.posTables.forEach((table) => {
      table.title = table.name;
      table.merge = true;
      table.cwrFbsectionId = section.cwrFbsectionId;
      table.sectionName = section.sectionName;
      table.table = table.name;
      table.color = "#a7c957";
      table.statusType = "OPN";
      table.originalIndex = index;
      tempData.push(table);
    });
    sectionData.push({ value: section.cwrFbsectionId, name: section.sectionName });
  });

  return { tempData, sectionData };
};

// Function to fetch occupied tables data
const fetchOccupiedTablesData = async () => {
  try {
    const query = `query {
      getTableStatus(tableId: null) {
        cSBunitID
        cSClientID
        isactive
        fbTableId
        fbSectionId
        guestName
        guestType
        referredBy
        status
        salesRepId
        guests
        fbtableStatusId
      }
    }`;

    const response = await retailInstance({
      data: { query },
    });

    return response?.data?.data?.getTableStatus || [];
  } catch (error) {
    console.error(error, "Error in fetch occupied tables");
  }
};

// Function to update table data with occupied information
const updateTableDataWithOccupiedInfo = (tempData, occupiedData) => {
  occupiedData.forEach((occupied) => {
    const table = tempData.find((t) => t.cwrFbTableId === occupied.fbTableId);
    if (table) {
      table.color = getColorByStatus(occupied.status);
      table.noOfPersons = occupied.guests;
      table.guestName = occupied.guestName;
      table.guestType = occupied.guestType;
      table.referredBy = occupied.referredBy;
      table.fbtableStatusId = occupied.fbtableStatusId;
      table.statusType = occupied.status;
      table.waiter = occupied.salesRepId;
    }
  });
};

// Helper function to get color by table status
const getColorByStatus = (status) => {
  switch (status) {
    case "OCU":
      return "#bc4749"; // Occupied
    case "RES":
      return "#f2e8cf"; // Reserved
    case "OPN":
    default:
      return "#a7c957"; // Open
  }
};

const upsertPOSLogService = async (tillId, userId, bUnitId, dayOpeningArray) => {
  try {
    const queryPayload = {
      query: `
        mutation {
          upsertPOSLog(order: {
            tillId: "${tillId}",
            userId: "${userId}", 
            bUnitId: "${bUnitId}", 
            lines: [${dayOpeningArray}]
          }) {
            status   
            message
          }
        }
      `,
    };
    const response = await retailInstance({
      data: queryPayload,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in upsert POS log service");
  }
};

const orderHistoryService = async (data) => {
  try {
    const response = await retailInstance({
      data: data,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in order history service");
  }
};

const generateGiftVoucher = async (data) => {
  try {
    const response = await retailInstance({
      data: data,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in generate gift voucher service");
  }
};

const posOrderProcessor1 = async (data) => {
  try {
    const response = await retailInstance({
      data: data,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in pos order processor service");
  }
};

const confirmPOSLayawayOrder1 = async (data) => {
  try {
    const response = await retailInstance({
      data: data,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in confirm pos layaway order service");
  }
};

const RFIDTag = async (data) => {
  try {
    const query = {
      query: `mutation{
      RFIDTag(rfidTag:[${data}]){
      status
      message
      }
      }`,
    };
    const response = await retailInstance({ data: query });
    return response;
  } catch (error) {
    console.error(error, "Error in RFID tag service");
  }
};

const verifyCouponsService = async (data) => {
  try {
    const response = await retailInstance({
      data: data,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in verify coupons service");
  }
};

const getNewOmsOrder = async (data) => {
  try {
    const response = await retailInstance({
      data: data,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in get new oms order service");
  }
};

const updateOmsOrder = async (data) => {
  try {
    const response = await retailInstance({
      data: data,
    });
    return response;
  } catch (error) {
    console.error(error, "Error in update oms order service");
  }
};

const verifyReturn = async (data) => {
  try {
    const response = await retailInstance({ data });
    return response;
  } catch (error) {
    console.error("Error in verifyReturn:", error);
    return null; // Ensure caller knows it failed
  }
};

const getProductsNearbyStockService = async (data) => {
  try {
    const response = await retailInstance({ data });
    return response;
  } catch (error) {
    console.error("Error in getProductsNearbyStock:", error);
    return null; // Ensure caller knows it failed
  }
};

const customerSearch = async (data) => {
  try {
    const response = await retailInstance({ data });
    return response;
  } catch (error) {
    console.error("Error in customerSearch:", error);
    return null; // Ensure caller knows it failed
  }
};

const verifyStock = async (data) => {
  try {
    const response = await retailInstance({ data });
    return response;
  } catch (error) {
    console.error("Error in verify stock:", error);
    return null; // Ensure caller knows it failed
  }
};

export {
  syncAllProductCategories,
  syncProductCategories,
  syncPosSaleTypes,
  syncProductsUom,
  syncProducts,
  syncPricingRules,
  syncOfflineDta,
  syncPosConfigData,
  syncLoyalityData,
  syncGiftCardData,
  getApprovals,
  syncRestaurantTables,
  syncTillData,
  getStoreOps,
  upsertStoreOps,
  tillRegistration,
  upsertPOSActivity,
  posConfigService,
  getTills,
  upsertPOSLogService,
  upsertTill,
  upsertLoyaltyTransaction,
  verifyCredit,
  discardGiftCard,
  discardCouponService,
  createCustomerService,
  updateCustomerService,
  getCustomerService,
  verifyGiftCardService,
  checkGiftCard,
  upsertGiftCardTransaction,
  salesHistoryService,
  orderHistoryService,
  generateGiftVoucher,
  posOrderProcessor1,
  RFIDTag,
  confirmPOSLayawayOrder1,
  verifyCouponsService,
  getNewOmsOrder,
  updateOmsOrder,
  verifyReturn,
  customerSearch,
  verifyStock,
  unlinkTill,
  getProductsNearbyStockService,
  generateToken
};
