import db from "../../database";
import Axios from "axios";

const TillCloseCWHCPrint = async (printReciptData, cashValues, x, cashToKeep) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let tillCloseTemplate = tillData.tillAccess.cwrTill.tillCloseTemplate.xmlcode2 ? JSON.parse(tillData.tillAccess.cwrTill.tillCloseTemplate.xmlcode2) : {};

  // Fetch and filter data from database
  const ordersData = (await db.ordersData.toArray()).filter((data) => data.tillSessionId === tillSession.tillSessionId);
  const tillEvents = (await db.tillEvents.toArray()).filter((data) => data.tillSessionId === tillSession.tillSessionId);
  // Group sales tax by VAT percentage
  const groupedByVatPercentage = {};
  (ordersData[0]?.salesTax || []).forEach((vat) => {
    const vatPercentage = String(vat.taxRate || "0"); // Ensure string
    if (!groupedByVatPercentage[vatPercentage]) {
      groupedByVatPercentage[vatPercentage] = {
        vatPercentage: vatPercentage,
        totalVatAmount: 0,
      };
    }
    groupedByVatPercentage[vatPercentage].totalVatAmount += parseFloat(vat.taxableAmt || 0);
  });
  const groupedSalesTax = Object.values(groupedByVatPercentage).map((group) => ({
    vatPercentage: group.vatPercentage,
    totalVatAmount: group.totalVatAmount.toFixed(2),
  }));

  // Group return tax by VAT percentage
  const groupedReturnByVatPercentage = {};
  (ordersData[0]?.returnTax || []).forEach((vat) => {
    const vatPercentage = String(vat.taxRate || "0"); // Ensure string
    if (!groupedReturnByVatPercentage[vatPercentage]) {
      groupedReturnByVatPercentage[vatPercentage] = {
        vatPercentage: vatPercentage,
        totalVatAmount: 0,
      };
    }
    groupedReturnByVatPercentage[vatPercentage].totalVatAmount += parseFloat(vat.taxableAmt || 0);
  });
  const groupedReturnTax = Object.values(groupedReturnByVatPercentage).map((group) => ({
    vatPercentage: group.vatPercentage,
    totalVatAmount: Math.abs(group.totalVatAmount).toFixed(2),
  }));
  // Single dynamic data object with all sections
  const printData = {
    storeName: tillData.tillAccess.csBunit.name,
    terminal: tillData.tillAccess.cwrTill.till,
    user: userData.user,
    openingTime: printReciptData?.openingTime || "",
    closingTime: printReciptData?.closingTime || "",
    netTotal: parseFloat(ordersData[0]?.netTotal || 0).toFixed(2),
    grossTotal: parseFloat(ordersData[0]?.grossTotal || 0).toFixed(2),
    netReturnTotal: Math.abs(parseFloat(ordersData[0]?.netReturnTotal || 0)).toFixed(2),
    grossReturnTotal: Math.abs(parseFloat(ordersData[0]?.grossReturnTotal || 0)).toFixed(2),
    totalRetailTransactions: (parseFloat(ordersData[0]?.grossTotal || 0) + parseFloat(ordersData[0]?.grossReturnTotal || 0)).toFixed(2),
    allPaymentsData: (tillEvents[0]?.allPaymentsData || []).map((data) => ({
      name: data.name,
      startingAmount: data.name === "Cash" && tillEvents[0]?.openingCash ? parseFloat(tillEvents[0].openingCash).toFixed(2) : "0.00",
      deposits:
        data.name === "Cash"
          ? (
              parseFloat(data.cashSaleAmount || 0) +
              (tillEvents[0]?.cashInOutData ? parseFloat(tillEvents[0].cashInOutData.cashIn || 0) + parseFloat(tillEvents[0].cashInOutData.pettCashIn || 0) : 0)
            ).toFixed(2)
          : parseFloat(data.expectedAmount || 0).toFixed(2),
      withdrawals:
        data.name === "Cash"
          ? (
              parseFloat(data.paymentReturn || 0) +
              (tillEvents[0]?.cashInOutData ? parseFloat(tillEvents[0].cashInOutData.cashOut || 0) + parseFloat(tillEvents[0].cashInOutData.pettCashOut || 0) : 0)
            ).toFixed(2)
          : parseFloat(data.paymentReturn || 0).toFixed(2),
      expectedAmount: data.expectedAmount ? parseFloat(data.expectedAmount).toFixed(2) : "0.00",
      amount: data.amount ? parseFloat(data.amount).toFixed(2) : "0.00",
      difference: data.difference ? parseFloat(data.difference).toFixed(2) : "0.00",
      toDeposit: data.name === "Cash" ? (parseFloat(data.amount || 0) - parseFloat(tillEvents[0]?.openingCash || 0)).toFixed(2) : parseFloat(data.amount || 0).toFixed(2),
      // toKeep: data.name === "Cash" && tillEvents[0]?.openingCash ? parseFloat(tillEvents[0].openingCash).toFixed(2) : "0.00",
      toKeep: data.name === "Cash" && cashToKeep && cashToKeep !== "" ? cashToKeep : "0.00",
    })),
    totalStartingAmount: tillEvents[0]?.openingCash ? parseFloat(tillEvents[0].openingCash).toFixed(2) : "0.00",
    totalDepositsAmount:
      (tillEvents[0]?.allPaymentsData || [])
        .reduce(
          (total, data) => total + (data.cashSaleAmount !== undefined && data.cashSaleAmount !== null ? parseFloat(data.cashSaleAmount) : parseFloat(data.expectedAmount || 0)),
          0
        )
        .toFixed(2) +
      (tillEvents[0]?.cashInOutData ? (parseFloat(tillEvents[0].cashInOutData.cashIn || 0) + parseFloat(tillEvents[0].cashInOutData.pettCashIn || 0)).toFixed(2) : "0.00"),
    totalWithdrawalsAmount:
      (tillEvents[0]?.allPaymentsData || []).reduce((total, data) => total + parseFloat(data.paymentReturn || 0), 0).toFixed(2) +
      (tillEvents[0]?.cashInOutData ? (parseFloat(tillEvents[0].cashInOutData.cashOut || 0) + parseFloat(tillEvents[0].cashInOutData.pettCashOut || 0)).toFixed(2) : "0.00"),
    totalExpectedAmount: (tillEvents[0]?.allPaymentsData || []).reduce((total, data) => total + parseFloat(data.expectedAmount || 0), 0).toFixed(2),
    totalCountedAmount: (tillEvents[0]?.allPaymentsData || []).reduce((total, data) => total + parseFloat(data.amount || 0), 0).toFixed(2),
    totalDifferenceAmount: (tillEvents[0]?.allPaymentsData || []).reduce((total, data) => total + parseFloat(data.difference || 0), 0).toFixed(2),
    totalToDepositAmount: (
      (tillEvents[0]?.allPaymentsData || []).reduce((total, data) => total + parseFloat(data.amount || 0), 0) - parseFloat(tillEvents[0]?.openingCash || 0)
    ).toFixed(2),
    // totalToKeepAmount: tillEvents[0]?.openingCash ? parseFloat(tillEvents[0].openingCash).toFixed(2) : "0.00",
    totalToKeepAmount: cashToKeep && cashToKeep !== "" ? cashToKeep : "0.00",
    groupedSalesTax: groupedSalesTax,
    groupedReturnTax: groupedReturnTax,
  };

  // Adjust totalDepositsAmount and totalWithdrawalsAmount for Cash-specific calculations
  printData.totalDepositsAmount = (
    parseFloat(printData.totalDepositsAmount) +
    (tillEvents[0]?.cashInOutData ? parseFloat(tillEvents[0].cashInOutData.cashIn || 0) + parseFloat(tillEvents[0].cashInOutData.pettCashIn || 0) : 0)
  ).toFixed(2);
  printData.totalWithdrawalsAmount = (
    parseFloat(printData.totalWithdrawalsAmount) +
    (tillEvents[0]?.cashInOutData ? parseFloat(tillEvents[0].cashInOutData.cashOut || 0) + parseFloat(tillEvents[0].cashInOutData.pettCashOut || 0) : 0)
  ).toFixed(2);

  // Process the JSON template with dynamic data
  const processedTemplate = processReceiptTemplate(tillCloseTemplate, printData);

  // console.log("TillCloseCWHCPrint Input:", { printReciptData, cashValues, x, cashToKeep });
  // console.log("Processed Template:", JSON.stringify(processedTemplate, null, 2));
  let prinReceipttData = JSON.stringify(processedTemplate, null, 2);
  Axios.post(`${printerURL}printer`, prinReceipttData, {
    headers: {
      "Content-Type": "text/plain",
    },
  })
    .then(() => {
      console.log("Print success !");
    })
    .catch((response) => {
      console.log("Print failed !", response);
    });
  return processedTemplate;
};

// JSON processing function
const processReceiptTemplate = (template, data) => {
  const processedTemplate = JSON.parse(JSON.stringify(template));

  processedTemplate.elements = processedTemplate.elements.flatMap((element) => {
    if (element.type === "text" && element.content && typeof element.content === "string") {
      element.content = replacePlaceholders(element.content, data);
    }

    if (element.type === "loop" && element.data && typeof element.data === "string") {
      const loopDataPath = element.data.replace(/{{|}}/g, "");
      const loopData = getDeepValue(data, loopDataPath) || [];
      if (Array.isArray(loopData)) {
        return loopData.flatMap((loopItem) => {
          return element.elements.map((loopElement) => {
            const newElement = JSON.parse(JSON.stringify(loopElement));
            if (newElement.content && typeof newElement.content === "string") {
              newElement.content = replacePlaceholders(newElement.content, loopItem);
            }
            return newElement;
          });
        });
      }
    }

    return element;
  });

  return processedTemplate;
};

// Placeholder replacement
const replacePlaceholders = (text, data) => {
  return text.replace(/{{([^}]+)}}/g, (match, placeholder) => {
    if (placeholder === "currentDateTime") return new Date().toLocaleString();
    const value = getDeepValue(data, placeholder);
    return value !== null && value !== undefined ? (typeof value === "number" ? value.toFixed(2) : value) : "";
  });
};

// Deep value retrieval
const getDeepValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : null), obj);
};

// Value formatting (optional, not used here but included for consistency)
const formatValue = (value, format) => {
  if (format === "currency" && typeof value === "number") return value.toFixed(2);
  return value;
};

export default TillCloseCWHCPrint;
