"use client";

import { useEffect, useState } from "react";
import { Modal, Progress, Button, Row, Col, message } from "antd";
import Axios from "axios";
import { getOAuthHeaders } from ".././constants/oAuthValidation";

const OrderNotification = ({ orderData, onAutoAccept, onReject }) => {
  const authHeaders = getOAuthHeaders();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(100);
  const [loading, setLoading] = useState(false);
  const countdownTime = 60;

  useEffect(() => {
    if (orderData) {
      if (orderData.queue === "order_created_queue") {
        // Only show notification for order creation
        setVisible(true);
        setProgress(100);
        let remainingTime = countdownTime;

        const interval = setInterval(() => {
          remainingTime -= 1;
          setProgress((remainingTime / countdownTime) * 100);

          if (remainingTime <= 0) {
            clearInterval(interval);
            handleAccept();
          }
        }, 1000);

        return () => clearInterval(interval);
      } else if (orderData.queue === "order_status_updates") {
        updateOrderStatus(orderData?.data?.new_state);
      }
      else if (orderData.queue === "delivery_status_updates") {
        updateOrderStatus(orderData?.data?.delivery_info?.current_state);
      }
    }
  }, [orderData]);

  const updateOrderStatus = async (newStatus) => {
    let setAuthTokens = authHeaders.access_token;
    const statusMapping = {
      Acknowledged: "AK",
      Cancelled: "CC",
      "Food Ready": "FR",
      Dispatched: "DI",
    };
    const statusCode = statusMapping[newStatus] || newStatus;
    setLoading(true);
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
            upsertOfdOrderStatus(order: [{
              sOrderID: "${orderData.queue === "order_created_queue" ? orderData?.data.orderId : orderData?.data?.additional_info?.external_channel?.order_id}"
              ofdStatus: ${orderData.queue === "delivery_status_updates" ? null : `"${statusCode}"`}
               metaData: ${orderData.queue === "delivery_status_updates"
              ? `[ { key: "Rider Status", value: "${JSON.stringify(orderData?.data).replace(/"/g, '\\"')}" } ]`
              : "[]"}            }]) { 
              status
              message   
            }
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      const result = response?.data?.data?.upsertOfdOrderStatus;
      if (result?.status === "200") {
        if (orderData.queue === "order_created_queue") {
          message.success(statusCode === "AK" ? "Order accepted!" : "Order rejected!");
        }
      } else {
        console.error("Status update failed:", result?.message);
        if (orderData.queue === "order_created_queue") {
          message.error("Failed to update order status.");
        }
      }
    } catch (error) {
      console.error("Error updating order status:", error);
      if (orderData.queue === "order_created_queue") {
        message.error("Error updating order status.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    setVisible(false);
    await updateOrderStatus("Acknowledged");
    // if (onAutoAccept) {
    //   onAutoAccept(orderData);
    // }
  };

  const handleReject = async () => {
    setVisible(false);
    await updateOrderStatus("Cancelled");
    // if (onReject) {
    //   onReject(orderData);
    // }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      mask={false}
      centered={false}
      width={300}
      style={{
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        position: "absolute",
        paddingTop: "0px",
        overflow: "hidden",
      }}
      bodyStyle={{
        padding: "20px 15px",
        paddingTop: "0px",
        borderRadius: "10px",
      }}
    >
      {/* Progress Bar (Attached at the top of the modal) */}
      <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
        <Progress
          percent={progress}
          status="active"
          showInfo={false}
          strokeColor="#2F3856"
          strokeWidth={5}
          style={{ marginBottom: "0px", borderRadius: "10px 10px 0 0" }}
        />
      </div>

      <div style={{ textAlign: "left", padding: "20px 10px 10px" }}>
        <h3 style={{ fontSize: "16px", marginBottom: "5px", color: "#0F0718" }}>
          New Order #{orderData?.orderNo}
        </h3>
        <p style={{ fontSize: "12px", marginBottom: "15px", color: "#504F53" }}>
          Order will be auto-confirmed in 1 minutes.
        </p>

        {/* Buttons in a Row */}
        <Row justify="center" gutter={10}>
          <Col>
            <Button
              type="default"
              size="large"
              onClick={handleReject}
              loading={loading}
              style={{
                color: "#2F3856",
                background: "#FFFFFF",
                borderRadius: "15px",
                fontSize: "14px",
                padding: "4px 8px",
                border: "1px solid #2F3856",
              }}
            >
              Reject
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={handleAccept}
              loading={loading}
              style={{
                color: "#FFFFFF",
                background: "#2F3856",
                borderRadius: "15px",
                fontSize: "14px",
                padding: "4px 8px",
                border: "none",
              }}
            >
              Accept
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default OrderNotification;