import { generateToken } from "../services";

// import { SSOURL } from "./serverConfig";
const SSOURL = process.env.REACT_APP_SSOURL;
const redirectUrl = process.env.REACT_APP_redirectURL;
const till = JSON.parse(localStorage.getItem("tillValue"));

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const getOAuthHeaders = () => {
    const cookies = document.cookie.split('; ');
    let access_token;
    let email;
    let username;
    let Enterprise;
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        // console.log(cookie);
        const name = cookie[0];
        const value = cookie[1];
        if (name.search('accessToken') > 0) {
            access_token = value;
        };
        if (name.search('LastAuthUser') > 0) {
            email = decodeURIComponent(value);
        };
        if (name === "username") {
            username = value;
        }
        if(name === "Enterprise"){
            Enterprise=value
        }  
    };

    // if (access_token === undefined && email === undefined) {
    //     window.location.assign(`${SSOURL}sign-in/?&redirect_uri=${redirectUrl}`);
    // } else {
    //     const decodedToken = parseJwt(access_token);
    //     const expirationTime = decodedToken.exp * 1000;
    //     const currentTime = Date.now();
    //     if (expirationTime < currentTime) {
    //         window.location.assign(`${SSOURL}refresh-token/?redirect_uri=${window.location.href}`);
    //     } else {
    //         return {
    //             access_token: `bearer ${access_token}`,
    //             email: email,
    //             Enterprise:Enterprise,
    //             username:username
    //         };
    //     };
    // };

    if (till?.cwr_till_id) {
        if (access_token && email && username) {
          const decodedToken = parseJwt(access_token);
          const expirationTime = decodedToken.exp * 1000;
          const currentTime = Date.now();
      
          if (expirationTime < currentTime) {
            generateToken();
            // Token is expired, handle accordingly
            // window.location.assign(`${SSOURL}refresh-token/?redirect_uri=${window.location.href}`);
          } else {
            // Token is valid, return authentication data
            return {
              access_token: `bearer ${access_token}`,
              email: email,
              Enterprise: Enterprise,
              username: username
            };
          }
        } else {
          // Till is there but no auth headers, return some default data or handle accordingly
          return {
            access_token: `bearer ${access_token}`,
            email: email,
            Enterprise: Enterprise,
            username: username
          };
        }
      } else {
        // No till, check for auth headers
        if (access_token) {
          const decodedToken = parseJwt(access_token);
          const expirationTime = decodedToken.exp * 1000;
          const currentTime = Date.now();
      
          if (expirationTime < currentTime) {
            // window.location.assign(`${SSOURL}refresh-token/?redirect_uri=${window.location.href}`);
            // Token is expired, redirect to refresh it
            // window.location.assign(`${SSOURL}refresh-token/?redirect_uri=${window.location.href}`);
            generateToken();
          } else {
            // Token is valid, return authentication data
            return {
              access_token: `bearer ${access_token}`,
              email: email,
              Enterprise: Enterprise,
              username: username
            };
          }
        } else {
            // console.log(till,"val")
          // No till and no auth headers, assign to URL
          // window.location.assign(`${SSOURL}sign-in/?&redirect_uri=${redirectUrl}`);
          // console.log("Assigning to URL");
          // if(!till){
          // // window.location.assign(`${SSOURL}sign-in/?&redirect_uri=${redirectUrl}`);
          // }
        }
      }
      
};
