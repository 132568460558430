import { pricingRuleController } from "./PricingRules/pricingRuleController";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import PoleDisplay from "../../../lib/printer/poleDisplay";
import CWPoleDisplay from "../../../lib/printer/CWPoleDisplay";
import db from "../../../database";
import moment from "moment";
import _ from "lodash";
import { stock } from "./stock";
import { discardGiftCard, discardCouponService } from "../../../services";
import { numberToWords } from "../../../utility/numberToWords";

const truncateToPrecision = (number, precision = 2) => {
  const factor = Math.pow(10, precision);
  return Math.trunc(number * factor) / factor;
};

export const addProductToCart = async (
  addToCart,
  qty,
  totalQtyFlag,
  cart,
  setCart,
  setSelectedProductInCart,
  deleteCart,
  processTotalManualDiscount,
  setLoader,
  salesRepresentDefaultLine,
  tillData,
  cartRef,
  productsCopy,
  salesRepresent,
  orderType,
  processBillDiscounts,
  modifiedPrice,
  setSelectedRowKeys,
  setOrderTimeDetails,
  setDisplayAddOnSelection,
  displayAddOnSelection,
  handleAddOnModal,
  manualDiscountTypes,
  CheckoutFlatDiscount,
  CheckoutPercentageDiscount,
  CheckoutTotalManualDiscount,
  setProductStock,
  setTableCards,
  dispatchItemSelection
) => {
  const weight = parseFloat(qty);
  const layAwayFlag = JSON.parse(localStorage.getItem("cartObj")).layAway || "N";
  const ObFlag = tillData.tillAccess.cwrTill.printTemplate.obController === "Y" ? true : false;
  const CWHardwareController = tillData.tillAccess.cwrTill.printTemplate.cwController === "Y" ? true : false;
  let addedToCart = cart.items;
  const precision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  let taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
  taxIncludeFlag = addToCart.taxFlag === "Y" ? "N" : taxIncludeFlag;
  let expiryDiscount = localStorage.getItem("expiryDiscount") !== null && localStorage.getItem("expiryDiscount") === "Y" ? true : false;
  let index = addedToCart.findIndex(
    (p) =>
      p.productId === addToCart.productId &&
      p.upc === addToCart.upc &&
      p.mBatchId === addToCart.mBatchId &&
      p.isReturn === false &&
      !expiryDiscount &&
      !p.discountType &&
      p?.selectedAddons?.length <= 0
  );
  let weightFlag = false;

  if (expiryDiscount === true || addToCart?.modified) {
    index = addedToCart.findIndex(
      (p) =>
        p.productId === addToCart.productId &&
        p.upc === addToCart.upc &&
        p.mBatchId === addToCart.mBatchId &&
        p.isReturn === false &&
        (expiryDiscount || addToCart?.modified) &&
        p.lineId === addToCart.lineId
    );
  }

  if (addedToCart[index]?.selectedAddons?.length > 0) {
    index = -1;
  }

  if (addedToCart[index]?.newCustomAttributes?.length > 0) {
    index = -1;
  }

  if (addToCart?.selectedAddons?.length > 0) {
    index = addedToCart.findIndex(
      (p) =>
        p.productId === addToCart.productId &&
        p.upc === addToCart.upc &&
        p.mBatchId === addToCart.mBatchId &&
        // p.isReturn === false &&
        p.lineId === addToCart.lineId &&
        p?.selectedAddons?.length > 0 &&
        p?.selectedAddons?.length === addToCart?.selectedAddons?.length
    );

    let indexFlag = true;
    addedToCart[index]?.selectedAddons?.forEach((addOn) => {
      // Compare with addToCart product add-ons
      let matchedAddOn = addToCart?.selectedAddons?.some((addOnItem) => {
        return addOn.mProductAddOnId === addOnItem.mProductAddOnId;
      });

      // If there is no matching add-on, set flag to false
      if (!matchedAddOn) {
        indexFlag = false;
      }
    });
    if (!indexFlag) {
      index = -1;
    }
  } else if (addToCart?.productAddons?.length > 0) {
    index = addedToCart.findIndex(
      (p) =>
        p.productId === addToCart.productId &&
        p.upc === addToCart.upc &&
        p.mBatchId === addToCart.mBatchId &&
        // p.isReturn === false &&
        p.lineId === addToCart.lineId
      // p?.selectedAddons?.length > 0 &&
      // p?.selectedAddons?.length === addToCart?.selectedAddons?.length
    );
  }

  if (addToCart?.newCustomAttributes?.length > 0) {
    index = addedToCart.findIndex(
      (p) =>
        p.productId === addToCart.productId &&
        p.upc === addToCart.upc &&
        p.mBatchId === addToCart.mBatchId &&
        p.isReturn === false &&
        p.lineId === addToCart.lineId &&
        p?.newCustomAttributes?.length > 0
    );
  }

  if (addToCart.isGiftCard === true) {
    index = addedToCart.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.lineId === addToCart.lineId);
  }

  if (addToCart.discountType) {
    index = addedToCart.findIndex(
      (p) =>
        p.productId === addToCart.productId &&
        p.upc === addToCart.upc &&
        p.mBatchId === addToCart.mBatchId &&
        p.isReturn === false &&
        !expiryDiscount &&
        p.lineId === addToCart.lineId
    );
  }

  if (addToCart.discount <= 0) {
    delete addToCart.priority;
  }

  if (addToCart.addNewLine === "Y") {
    // Find the index based on additional conditions
    index = addedToCart.findIndex(
      (p) => p.upc === addToCart.upc && p.mBatchId === addToCart.mBatchId && !p.isReturn && !expiryDiscount && addToCart.addNewLine === "Y" && addToCart.lineId === p.lineId
    );
  }

  if (addToCart.productSegment === "CB" && (!addToCart.childItems || addToCart.childItems.length <= 0)) {
    // Open popup to select child items
    localStorage.setItem("productSegment", "CB");
    const itemsToDispatch = addToCart || [];
    const { selectedItems = [] } = await new Promise((resolve) => {
      dispatchItemSelection(itemsToDispatch, (eventData) => {
        resolve({ selectedItems: eventData?.selectedItems || [] });
      });
    });
    localStorage.removeItem("productSegment");
    // Assign selected child items
    addToCart.childItems = selectedItems;
  }

  // Always recalculate sunit price, even when increasing quantity
  if (addToCart.childItems && addToCart.childItems.length > 0) {
    addToCart.salePrice = addToCart.childItems.reduce((total, childItem) => {
      const sp = parseFloat(childItem.sunitprice);
      const mrp = sp * childItem.weight;
      const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + childItem.taxRate / 100) : (mrp * childItem.taxRate) / 100;

      childItem.taxAmount = tax;
      childItem.nettotal = parseFloat(truncateToPrecision(taxIncludeFlag === "Y" ? mrp : mrp + tax, precision));

      return total + childItem.nettotal - tax;
    }, 0);

    // Ensure sunitprice updates every time
    addToCart.sunitprice = addToCart.salePrice;
    addToCart.realPrice = addToCart.salePrice;
    addToCart.originalPrice = addToCart.salePrice;
  }

  if (index >= 0 && !addedToCart?.[index]?.parkedItem && !addedToCart?.[index]?.discountType) {
    const isQtyDecimal = addToCart.isQtyDesimal;
    let newWeight = totalQtyFlag
      ? parseFloat(truncateToPrecision(weight, isQtyDecimal))
      : parseFloat(truncateToPrecision(addedToCart[index].weight, isQtyDecimal)) + parseFloat(truncateToPrecision(weight, isQtyDecimal));

    addedToCart[index].weight = newWeight;
    addToCart.weight = newWeight;

    if (parseFloat(addedToCart[index].weight) <= 0) {
      weightFlag = true;
      setSelectedProductInCart({});
      let setAuthTokens;

      if (addToCart.isGiftCard || addedToCart[index]?.iscoupon) {
        const authHeaders = await getOAuthHeaders();
        if (authHeaders && authHeaders.access_token) {
          setAuthTokens = authHeaders.access_token;
        }
      }
      const serverUrl = process.env.REACT_APP_serverUrl;

      if (addedToCart[index]?.iscoupon) {
        const response = await discardCouponService(addedToCart[index].couponInput, addedToCart[index].referenceId);
        // const discardCoupon = {
        //   query: `query{
        //       discardCoupon(couponcode:"${addedToCart[index].couponInput}",referenceId: "${addedToCart[index].referenceId}"){
        //           status
        //           message
        //       }
        //   }`,
        // };
        // Axios({
        //   url: serverUrl,
        //   method: "POST",
        //   data: discardCoupon,
        //   headers: {
        //     "Content-Type": "Application/json",
        //     Authorization: `${setAuthTokens}`,
        //   },
        // });
      }
      if (cart.couponInput?.length > 0) {
        cart.couponInput = cart.couponInput?.filter((coupon) => {
          if (addedToCart[index].couponInput !== coupon) {
            return coupon;
          }
        });
      }

      // if (addToCart.selectedAddons.length > 0) {
      //   checkAndShowAddOnModal(addToCart.selectedAddons);
      // }

      if (addToCart.isGiftCard) {
        let giftCardDataIndex = cart?.giftCardData?.findIndex((giftCard) => giftCard.number === addToCart.cardNo);
        // const discardGiftCard = {
        //   query: `mutation{
        //             discardGiftCard(giftCards: [{cardNo: "${cart.giftCardData[giftCardDataIndex].number}" , referenceNo : "${cart.giftCardData[giftCardDataIndex].refId}"}]){
        //               status
        //               message
        //       }
        //   }`,
        // };
        // Axios({
        //   url: serverUrl,
        //   method: "POST",
        //   data: discardGiftCard,
        //   headers: {
        //     "Content-Type": "Application/json",
        //     Authorization: `${setAuthTokens}`,
        //   },
        // }).then((res) => {
        //   cart.giftCardData.splice(giftCardDataIndex, 1);
        // });
        try {
          const res = await discardGiftCard(cart.giftCardData[giftCardDataIndex].number, cart.giftCardData[giftCardDataIndex].refId);

          // Remove the gift card from the array only if the request succeeds
          cart.giftCardData.splice(giftCardDataIndex, 1);
        } catch (error) {
          console.error("Error discarding gift card:", error);
          message.error("Failed to discard gift card.");
        }
      }
      message.warning(`${addToCart.name} Removed Successfully`, 0.1);
      if (cart.advancePayment <= 0 || !cart.advancePayment) {
        if (cart?.manualDiscountData?.lineLevelDiscount?.length > 0) {
          cart.manualDiscountData.lineLevelDiscount = cart.manualDiscountData.lineLevelDiscount.filter((lineItem) => lineItem.productId !== addedToCart[index].productId);
        }
        addedToCart.splice(index, 1);
      } else {
        addedToCart[index].nettotal = 0;
        addedToCart[index].unitTax = 0;
        addedToCart[index].netStd = 0;
      }
    } else {
      let manualDiscountInput = 0;
      if (addedToCart[index].discountType === "PD") {
        manualDiscountInput = addedToCart[index].discountValue;
      }
      const discountAmt = (parseFloat(manualDiscountInput) / 100) * parseFloat(addedToCart[index].realPrice);
      addedToCart[index].discount = totalQtyFlag ? discountAmt * weight : discountAmt * addedToCart[index].weight;
      const addOnsNewProduct = _.sumBy(addedToCart?.selectedAddons, "price");
      const addOnsPriceSum = _.sumBy(addedToCart[index]?.selectedAddons, "price");
      const sp = parseFloat(addedToCart[index].realPrice) + addOnsPriceSum - discountAmt;
      let mrp = totalQtyFlag ? parseFloat(sp) * weight : parseFloat(sp) * addedToCart[index].weight;
      const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + addedToCart[index].taxRate / 100) : (mrp * addedToCart[index].taxRate) / 100;
      addedToCart[index].taxAmount = parseFloat(truncateToPrecision(tax, precision));
      addedToCart[index].nettotal = taxIncludeFlag === "Y" ? parseFloat(truncateToPrecision(mrp, precision)) : parseFloat(truncateToPrecision(mrp + tax, precision));
      addedToCart[index].modified = addToCart?.modified ? true : false;
      delete addedToCart[index].nextRule;
      if (addedToCart[index].discount <= 0) {
        delete addedToCart[index].priority;
      }
      message.success(`${addToCart.name} ${qty < 0 ? "Removed Successfully" : "Added Successfully"}`, 0.1);
    }
  } else if (index >= 0 && !addedToCart?.[index]?.parkedItem && addedToCart?.[index]?.discountType) {
    addedToCart[index].weight = totalQtyFlag
      ? parseFloat(truncateToPrecision(weight, addToCart.isQtyDesimal))
      : parseFloat(truncateToPrecision(addedToCart[index].weight, addToCart.isQtyDesimal)) + parseFloat(parseFloat(truncateToPrecision(weight, addToCart.isQtyDesimal)));
    addToCart.weight = parseFloat(truncateToPrecision(addedToCart[index].weight, addToCart.isQtyDesimal));
    if (parseFloat(addedToCart[index].weight) === 0) {
      setSelectedProductInCart({});
      message.warning(`${addToCart.name} Removed Successfully`, 0.1);
      if (cart.advancePayment <= 0) {
        if (cart?.manualDiscountData?.lineLevelDiscount?.length > 0) {
          cart.manualDiscountData.lineLevelDiscount = cart.manualDiscountData.lineLevelDiscount.filter((lineItem) => lineItem.productId !== addedToCart[index].productId);
        }
        addedToCart.splice(index, 1);
      } else {
        addedToCart[index].nettotal = 0;
        addedToCart[index].unitTax = 0;
        addedToCart[index].netStd = 0;
        addedToCart[index].salePrice = 0;
      }
    }
  } else if (index >= 0 && addedToCart?.[index]?.parkedItem) {
    const isQtyDecimal = addToCart.isQtyDesimal;
    let newWeight = totalQtyFlag
      ? parseFloat(truncateToPrecision(weight, isQtyDecimal))
      : parseFloat(truncateToPrecision(addedToCart[index].weight, isQtyDecimal)) + parseFloat(truncateToPrecision(weight, isQtyDecimal));

    addedToCart[index].weight = newWeight;
    addToCart.weight = newWeight;
    if (parseFloat(addedToCart[index].weight) <= 0) {
      addedToCart.splice(index, 1);

      setCart((prevCart) => {
        const updatedItems = prevCart.items.filter((cartItem) => cartItem?.lineId !== addedToCart?.[index]?.lineId);
        const updatedCart = { ...prevCart, addedToCart: updatedItems };
        localStorage.setItem("cartObj", JSON.stringify(updatedCart));
        return updatedCart;
      });

      try {
        const tableData = await db.tableData.toArray();
        const updatedTableData = tableData.map((table) => {
          if (table.title === cart.tableNo) {
            return { ...table, items: [...cart.items] };
          }
          return table;
        });
        for (const updatedTable of updatedTableData) {
          if (updatedTable.title === cart.tableNo) {
            await db.tableData.put(updatedTable);
          }
        }
        setTableCards(updatedTableData);
      } catch (error) {
        console.error("Error updating table data:", error);
      }

      try {
        const fbOrderData = await db.fbOrderData.toArray();

        const updatedFbOrderData = fbOrderData.map((order) => {
          if (order.cart.sOrderID === cart.sOrderID) {
            return {
              ...order,
              cart: {
                ...order.cart,
                items: [...cart.items],
              },
            };
          }
          return order;
        });

        for (const updatedOrder of updatedFbOrderData) {
          if (updatedOrder.table === cart.tableNo) {
            await db.fbOrderData.put(updatedOrder);
          }
        }
      } catch (error) {
        console.error("Error updating fbOrderData:", error);
      }
    } else {
      let manualDiscountInput = 0;
      if (addedToCart[index].discountType === "PD") {
        manualDiscountInput = addedToCart[index].discountValue;
      }
      const discountAmt = (parseFloat(manualDiscountInput) / 100) * parseFloat(addedToCart[index].realPrice);
      addedToCart[index].discount = totalQtyFlag ? discountAmt * weight : discountAmt * addedToCart[index].weight;
      const addOnsNewProduct = _.sumBy(addedToCart?.selectedAddons, "price");
      const addOnsPriceSum = _.sumBy(addedToCart[index]?.selectedAddons, "price");
      const sp = parseFloat(addedToCart[index].realPrice) + addOnsPriceSum - discountAmt;
      let mrp = totalQtyFlag ? parseFloat(sp) * weight : parseFloat(sp) * addedToCart[index].weight;
      const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + addedToCart[index].taxRate / 100) : (mrp * addedToCart[index].taxRate) / 100;
      addedToCart[index].taxAmount = parseFloat(truncateToPrecision(tax, precision));
      addedToCart[index].nettotal = taxIncludeFlag === "Y" ? parseFloat(truncateToPrecision(mrp, precision)) : parseFloat(truncateToPrecision(mrp + tax, precision));
      addedToCart[index].modified = addToCart?.modified ? true : false;
      delete addedToCart[index].nextRule;
      if (addedToCart[index].discount <= 0) {
        delete addedToCart[index].priority;
      }
      message.success(`${addToCart.name} ${qty < 0 ? "Removed Successfully" : "Added Successfully"}`, 0.1);
      try {
        const tableData = await db.tableData.toArray();
        const updatedTableData = tableData.map((table) => {
          if (table.title === cart.tableNo) {
            return { ...table, items: [...cart.items] };
          }
          return table;
        });
        for (const updatedTable of updatedTableData) {
          if (updatedTable.title === cart.tableNo) {
            await db.tableData.put(updatedTable);
          }
        }
        setTableCards(updatedTableData);
      } catch (error) {
        console.error("Error updating table data:", error);
      }

      try {
        const fbOrderData = await db.fbOrderData.toArray();
        const updatedFbOrderData = fbOrderData.map((order) => {
          if (order.cart.sOrderID === cart.sOrderID) {
            const updatedItems = order.cart.items.map((item) => {
              if (item.lineId === addedToCart[index].lineId) {
                return addedToCart[index]; // Update the modified item
              }
              return item;
            });
            const total = updatedItems.reduce((sum, item) => sum + item.nettotal, 0);
            const totalQty = updatedItems.reduce((sum, item) => sum + item.weight, 0);
            const tax = updatedItems.reduce((sum, item) => sum + item.taxAmount, 0);
            const updatedOrder = {
              ...order,
              cart: {
                ...order.cart,
                items: updatedItems,
                total: total,
                tax: tax,
                totalQty: totalQty,
              },
            };

            updatedOrder.totalAmount = updatedItems.reduce((sum, item) => sum + item.nettotal, 0);
            // updatedOrder.tax = updatedItems.reduce((sum, item) => sum + item.taxAmount, 0);

            return updatedOrder;
          }
          return order;
        });
        for (const updatedOrder of updatedFbOrderData) {
          if (updatedOrder.table === cart.tableNo) {
            await db.fbOrderData.put(updatedOrder);
          }
        }
      } catch (error) {
        console.error("Error updating fbOrderData:", error);
      }
    }
  } else {
    // Create a shallow copy of addToCart to work with the updated item
    let newAddToCart = { ...addToCart };

    newAddToCart.weight = parseFloat(truncateToPrecision(weight, newAddToCart.isQtyDesimal));

    const addOnsPriceSum = _.sumBy(newAddToCart?.selectedAddons, "price") || 0;
    delete newAddToCart.nextRule;

    const mrp = modifiedPrice && newAddToCart.taxFlag === "N" ? modifiedPrice : (parseFloat(newAddToCart.salePrice) + addOnsPriceSum * weight) * parseFloat(weight);

    const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + newAddToCart.taxRate / 100) : mrp * (newAddToCart.taxRate / 100);
    let netStd = parseFloat(newAddToCart.sunitprice - newAddToCart.sunitprice / (1 + newAddToCart.taxRate / 100));
    newAddToCart.taxAmount = tax;
    newAddToCart.nettotal = parseFloat(
      taxIncludeFlag === "Y" ? mrp - (newAddToCart.discount ? parseFloat(newAddToCart.discount) : 0) : mrp - (newAddToCart.discount ? parseFloat(newAddToCart.discount) : 0) + tax
    );

    newAddToCart.nettotal = parseFloat(truncateToPrecision(newAddToCart.nettotal, precision));

    newAddToCart.unitTax = newAddToCart?.isReturn
      ? newAddToCart.unitTax
      : parseFloat(truncateToPrecision(newAddToCart.salePrice - newAddToCart.salePrice / (1 + newAddToCart.taxRate / 100), precision));

    newAddToCart.netStd =
      taxIncludeFlag === "Y" ? (newAddToCart.sunitprice > 0 ? parseFloat(truncateToPrecision(newAddToCart.sunitprice - netStd, precision)) : 0) : newAddToCart.sunitprice ?? 0;

    newAddToCart.lineId = uuidv4().replace(/-/g, "").toUpperCase();
    newAddToCart.salesRepId = salesRepresentDefaultLine.salesRepresentId;
    newAddToCart.salesRepName = Object.keys(salesRepresent).length > 0 ? salesRepresentDefaultLine.name : "";

    // Add the newly created item to the cart
    addedToCart.unshift(newAddToCart);

    let latestIndex = 0; // Since we used unshift, the latest item is at index 0
    setSelectedRowKeys([latestIndex]);
    setSelectedProductInCart(newAddToCart);
    addToCart = { ...newAddToCart };
    message.success(`${newAddToCart.name} ${qty < 0 ? "Removed Successfully" : "Added Successfully"}`, 0.1);
    if (CWHardwareController) {
      CWPoleDisplay(newAddToCart, "Add item to cart");
    } else {
      PoleDisplay(newAddToCart, "Add item to cart");
    }
  }
  // addedToCart = clubProductsWithAddOns(addedToCart, precision);

  let stockCheckResult = true;
  let isFinalValidation = false;
  if (addToCart.type === "I" && addToCart.layAway !== "Y" && addToCart.productSegment !== "CB" && navigator.onLine && cart.layAway !== "Y") {
    stockCheckResult = await stock({
      cart: { ...cart, items: addedToCart },
      setCart,
      tillData,
      setProductStock,
      isFinalValidation,
    });
    if (!stockCheckResult) {
      setSelectedProductInCart({});
      const tempCart = JSON.parse(localStorage.getItem("cartObj"));
      setCart(tempCart);
      return;
    }
  }

  let totalTax = 0;
  let totalPrice = 0;
  let totalItemsQty = 0;
  let totalDiscounts = 0;
  for (let i = 0; i < addedToCart.length; i += 1) {
    totalPrice += parseFloat(truncateToPrecision(addedToCart[i].nettotal, precision));
    totalItemsQty += addedToCart[i].weight;
    totalTax += parseFloat(truncateToPrecision(addedToCart[i].taxAmount, precision));
    totalDiscounts += parseFloat(truncateToPrecision(addedToCart[i].discount, precision));
    addedToCart[i].key = i;
  }

  const roundOffValue = Math.round(totalPrice);
  const totalRoundOff = totalPrice - roundOffValue;
  if (cart.items.length === 0) {
    deleteCart();
  }
  let cartObj = {
    ...cart,
    items: [...addedToCart],
    total: parseFloat(truncateToPrecision(totalPrice, precision)),
    tax: totalTax,
    discount: totalDiscounts,
    totalQty: totalItemsQty,
    roundOff: totalRoundOff,
  };

  if (cartObj.items.length === 0 && tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId === cart.customer.cwrCustomerId && cartObj.description !== "") {
    let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
      ? JSON.parse(localStorage.getItem("orderTimeDetails"))
      : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
    orderTimeDetails = {
      ...orderTimeDetails,
      orderStartTime: "", // Update orderStartTime to current time
    };
    localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
  }

  let updatedCart = cartObj;

  if (cartObj.items?.length > 0) {
    let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
      ? JSON.parse(localStorage.getItem("orderTimeDetails"))
      : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
    orderTimeDetails = {
      ...orderTimeDetails,
      paymentStartTime: "",
      orderStartTime: orderTimeDetails.orderStartTime !== "" ? orderTimeDetails.orderStartTime : moment(new Date()).format("DD-MM-YYYY HH:mm:ss"), // Update orderStartTime to current time
    };
    localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    updatedCart = addedToCart.isReturn ? cartObj : await pricingRuleController(addToCart, cartObj, cart, setCart, cartRef, orderType);
  }

  if (updatedCart?.couponInput?.length > 0 && cart?.statusType !== "layAway") {
    await Promise.all(
      updatedCart.couponInput.map(async (coupon) => {
        updatedCart = await pricingRuleController(
          addToCart,
          updatedCart,
          cart,
          setCart,
          cartRef,
          orderType,
          true,
          coupon.couponCode,
          coupon.referenceId,
          coupon.mPricingCouponId,
          coupon.mPricingruleId
        );
      })
    );
  }

  const allDiscounts = [...(updatedCart?.manualDiscountData?.lineLevelDiscount || []), ...(updatedCart?.manualDiscountData?.totalLevelDiscount || [])].sort(
    (a, b) => a.sequenceNo - b.sequenceNo
  );

  const uniqueDiscounts = allDiscounts.reduce((acc, item) => {
    if (!acc.some((discount) => discount.pricingRule === item.pricingRule)) {
      acc.push(item);
    }
    return acc;
  }, []);

  uniqueDiscounts.forEach((item) => {
    const pricingRule = manualDiscountTypes.find((md) => md.mPricingrulesId === item.pricingRule);

    if (pricingRule) {
      if (pricingRule.type === "FD" || pricingRule.type === "PD") {
        updatedCart.items.forEach(async (cartItem) => {
          const isLineLevelDiscount = updatedCart?.manualDiscountData?.lineLevelDiscount?.some((d) => d.pricingRule === item.pricingRule);
          let allDiscounts = cartItem?.allDiscounts || [];
          let matchedRuleIndex = allDiscounts.findIndex((d) => d.mPricingruleId === pricingRule.mPricingrulesId);
          if (isLineLevelDiscount && matchedRuleIndex !== -1) {
            const applyDiscount = pricingRule.type === "FD" ? CheckoutFlatDiscount : CheckoutPercentageDiscount;
            cartItem.priority = 0;
            updatedCart = await applyDiscount(cartItem, pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
          }
        });
      }

      if (pricingRule.type === "TD") {
        CheckoutTotalManualDiscount(pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
      }
    }
  });

  if (cart.totalBillDicount) {
    const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(cart.totalBillDicount).toArray();
    processBillDiscounts(matchingPricingRules[0], cartObj, true);
    updatedCart = JSON.parse(localStorage.getItem("cartObj"));
  }

  if (cart.manualDiscountApplied && cart.manualDiscountApplied !== 0 && cart.discountType === "TD") {
    setLoader(true);
    setTimeout(() => {
      processTotalManualDiscount(cart.manualDiscountApplied);
      setLoader(false);
    }, 200);
  }

  const itemSummery = {
    decimalItems: { totalQty: 0, totalAmount: 0 },
    nonDecimalItems: { totalQty: 0, totalAmount: 0 },
    itemsQty: 0,
    items: 0,
  };

  // Group items by tax rate
  const groupedItems = (updatedCart.items || []).reduce((acc, item) => {
    const key = item.tax?.rate ?? item.taxRate ?? "0";
    acc[key] = acc[key] || [];
    acc[key].push(item);
    return acc;
  }, {});

  // Calculate tax totals with SGST and CGST
  const groupedItemsByTaxRate = Object.entries(groupedItems).map(([taxRate, items]) => {
    const totalTaxAmount = items.reduce((sum, item) => {
      const taxValue = (item.tax?.rate !== undefined && item.tax?.rate !== null ? item.taxAmount : item.taxAmount) || 0;
      return sum + parseFloat(truncateToPrecision(taxValue, precision));
    }, 0);

    const totalNetTotal = items.reduce((sum, item) => {
      const netValue = (item.tax?.rate !== undefined && item.tax?.rate !== null ? item.nettotal : item.nettotal) || 0;
      return sum + parseFloat(truncateToPrecision(netValue, precision));
    }, 0);

    // Calculate SGST and CGST (assuming they're either explicitly provided or half of total tax)
    const totalSgstAmount = items.reduce((sum, item) => {
      const sgstValue = item.sgst || item.tax?.sgst || parseFloat(item.taxAmount || 0) / 2;
      return sum + parseFloat(truncateToPrecision(sgstValue, precision));
    }, 0);

    const totalCgstAmount = items.reduce((sum, item) => {
      const cgstValue = item.cgst || item.tax?.cgst || parseFloat(item.taxAmount || 0) / 2;
      return sum + parseFloat(truncateToPrecision(cgstValue, precision));
    }, 0);

    return {
      taxRate,
      totalLinenet: parseFloat(truncateToPrecision(totalNetTotal - totalTaxAmount, precision)),
      totalLinetax: parseFloat(truncateToPrecision(totalTaxAmount, precision)),
      totalLinegross: parseFloat(truncateToPrecision(totalNetTotal, precision)),
      totalSgst: parseFloat(truncateToPrecision(totalSgstAmount, precision)),
      totalCgst: parseFloat(truncateToPrecision(totalCgstAmount, precision)),
    };
  });

  // Process cart items
  let updatedTotalTax = 0;
  let updatedTotalPrice = 0;
  let updatedTotalItemsQty = 0;
  let updatedTotalDiscounts = 0;

  updatedCart.items = updatedCart.items.map((item, i) => {
    const precision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
    const nettotalFixed = parseFloat(truncateToPrecision(item.nettotal, precision));
    const taxAmountFixed = parseFloat(truncateToPrecision(item.taxAmount, precision));
    const discountFixed = item.discount ? parseFloat(truncateToPrecision(item.discount, precision)) : 0;

    // Update totals
    updatedTotalPrice += nettotalFixed;
    updatedTotalItemsQty += item.weight;
    updatedTotalTax += taxAmountFixed;
    updatedTotalDiscounts += discountFixed;

    item.discount = discountFixed;
    item.key = i;

    if (!item.isGiftCard) {
      const unitPrice = isFinite(item.nettotal / item.weight) ? item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate : 0;
      const lineTax = item.listPrice * (1 + item.taxRate / 100);
      const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
      const netList = taxIncludeFlag === "Y" ? truncateToPrecision(item.listPrice - item.listPrice / (1 + item.taxRate / 100), precision) : item.listPrice;
      const gross_list = taxIncludeFlag === "Y" ? item.listPrice : truncateToPrecision(lineTax, precision);

      Object.assign(item, {
        linetax: nettotalFixed ? taxAmountFixed : 0,
        linenet: nettotalFixed ? truncateToPrecision(item.nettotal - item.taxAmount, precision) : 0,
        linegross: nettotalFixed || 0,
        netunit: nettotalFixed ? truncateToPrecision(unitPrice, precision) : 0,
        listprice: nettotalFixed ? item.listPrice : 0,
        grossunit: nettotalFixed ? truncateToPrecision(grossUnit, precision) * (item.isReturn ? -1 : 1) : 0,
        grossstd: nettotalFixed ? item.sunitprice : 0,
        grosslist: nettotalFixed ? gross_list : 0,
        netList: nettotalFixed ? netList : 0,
        unitPrice: nettotalFixed ? truncateToPrecision(unitPrice, precision) : 0,
        taxAmount: nettotalFixed ? taxAmountFixed : 0,
      });
    }

    // Update summary
    const isDecimal = item.uomData?.[0]?.decimal === "Y" || item.isDecimal;
    const summaryKey = isDecimal ? "decimalItems" : "nonDecimalItems";
    itemSummery.itemsQty += item.weight;
    itemSummery.items += 1;
    itemSummery[summaryKey].totalQty += item.weight;
    itemSummery[summaryKey].totalAmount += parseFloat(truncateToPrecision(item.nettotal, precision));

    return item;
  });
  const calculateRoundingDifference = (value) => {
    // Round up the payment to the nearest multiple of 0.25
    const roundedPayment = Math.ceil(value / 0.25) * 0.25;

    // Calculate the raw overpayment (before considering change)
    const rawOverpayment = roundedPayment - Math.abs(updatedTotalPrice);
    return parseFloat(truncateToPrecision(rawOverpayment, 2));
  };
  index = updatedCart?.items.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc);
  // if (index !== -1 && addToCart.addNewLine !== "Y") {
  //   // Remove the item from its current position
  //   const removedItem = updatedCart?.items.splice(index, 1)[0];

  //   // Place the item at the beginning of the array
  //   updatedCart?.items.unshift(removedItem);
  // }

  if (index !== -1 && addToCart.addNewLine !== "Y") {
    const userAccessController = JSON.parse(tillData?.tillAccess?.userAccessController || {});
    const layout = userAccessController.layout;
    if (layout === "3") {
      updatedCart.items[index].quantity += addToCart.quantity;
      // updatedCart.items[index].weight += addToCart.weight;
    } else {
      const removedItem = updatedCart?.items.splice(index, 1)[0];

      // Place the item at the beginning of the array
      updatedCart?.items.unshift(removedItem);
    }
  }
  const updatedTotalRoundOff = calculateRoundingDifference(updatedTotalPrice);
  // Sum Line Totals
  const sumLineTotals = (lines) => {
    let totalLineGross = 0;
    let totalLineTax = 0;

    lines.forEach((line) => {
      totalLineGross += line.nettotal;
      totalLineTax += line.taxAmount;
    });

    return { totalLineGross, totalLineTax };
  };
  // Compare and Adjust Order Totals

  const adjustOrderTotals = (order, lines) => {
    const { totalLineGross, totalLineTax } = sumLineTotals(lines);
    let adjustedOrderGross = order.total;
    let adjustedOrderTax = order.tax;

    const grossDifference = totalLineGross - adjustedOrderGross;
    const taxDifference = totalLineTax - adjustedOrderTax;

    if (Math.abs(grossDifference) > 0.01) {
      adjustedOrderGross += grossDifference;
    }

    if (Math.abs(taxDifference) > 0.01) {
      adjustedOrderTax += taxDifference;
    }

    return {
      ...order,
      total: adjustedOrderGross,
      tax: adjustedOrderTax,
    };
  };
  updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);
  let finalCartObj = {
    ...updatedCart,
    layAway: layAwayFlag,
    items: [...updatedCart?.items],
    discount: updatedTotalDiscounts,
    totalQty: updatedTotalItemsQty,
    roundOff: updatedTotalRoundOff,
    total: updatedTotalPrice,
    tax: updatedTotalTax,
    payments: updatedCart?.items.length > 0 ? updatedCart.payments : [],
    paid: updatedCart?.items.length > 0 ? updatedCart.paid : 0,
    subTotal: parseFloat((updatedTotalPrice - updatedTotalTax).toFixed(2)),
    amountInWords: numberToWords(updatedTotalPrice),
    itemsSummery: itemSummery,
    groupedItemsByTaxRate,
  };
  setSelectedProductInCart(addToCart);
  setCart(finalCartObj);
  localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
};
