export const numberToWords = (num) => {
  if (num === 0) return "Zero Rupees Only";

  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

  const scales = ["", "Thousand", "Million", "Billion", "Trillion"];
  const indianScales = ["", "Thousand", "Lakh", "Crore"];

  // Function to convert numbers up to 999
  const convertToWords = (n) => {
    let word = "";

    if (n >= 100) {
      word += ones[Math.floor(n / 100)] + " Hundred ";
      n %= 100;
    }

    if (n >= 20) {
      word += tens[Math.floor(n / 10)] + " ";
      n %= 10;
    }

    if (n > 0) {
      word += ones[n] + " ";
    }

    return word.trim();
  };

  // Split integer and decimal parts
  const [integerPart, decimalPart] = num.toString().split(".");

  let number = parseInt(integerPart, 10);
  let words = "";
  let scaleIndex = 0;

  // Convert integer part using Indian numbering system (Lakh, Crore)
  const parts = [];
  if (number >= 10000000) {
    parts.push(Math.floor(number / 10000000));
    number %= 10000000;
  }
  if (number >= 100000) {
    parts.push(Math.floor(number / 100000));
    number %= 100000;
  }
  if (number >= 1000) {
    parts.push(Math.floor(number / 1000));
    number %= 1000;
  }
  if (number > 0) {
    parts.push(number);
  }

  for (let i = 0; i < parts.length; i++) {
    if (parts[i] > 0) {
      words += convertToWords(parts[i]) + " " + indianScales[parts.length - 1 - i] + " ";
    }
  }

  words = words.trim() + " Rupees";

  // Convert decimal part if it exists
  if (decimalPart && parseInt(decimalPart) > 0) {
    words += " and " + convertToWords(parseInt(decimalPart)) + " Paise";
  }

  return words + " Only";
};
